import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LinksSectionItem, selectLinksUser} from '../../../features/stores/linksUserSlicer';
import BaseModal from '../components/BaseModal';
import DeleteButton from '../components/DeleteButton';
import EditableImageInput from '../components/EditableImageInput';
import EditableTextInput from '../components/EditableTextInput';
import SaveButton from '../components/SaveButton';
import TextInput from '../components/TextInput';
import ToggleButton from '../components/ToggleButton';
import LinkModal, {LinkModalProps} from '../LinkModal';
import currencies from '../../../constants/currencies';
import Select from '../components/Select';
import {MonetizeType, initialLink} from './types';
import PriceBreakdown from '../components/PriceBreakdown';
import {SellMyProduct} from '../../../constants/socialMedia';
import UploadButton from '../components/UploadButton';
import PriceInput, {useMonetizePrice} from '../components/PriceInput';
import {ReactComponent as TrashIcon} from '../../../assets/images/al_delete_red.svg';
import {isValidHttpUrl} from '../../../features/util';
import styles from './SellProductLinkModal.module.scss';
import Alert from '@material-ui/lab/Alert';
import { PrimaryButton } from '../../Buttons/PrimaryButton';
import { useSelector } from 'react-redux';

const contentTypes = SellMyProduct.filter((item) => item.enable);
const DEFAULT_CURRENCY = 'usd';
const DEFAULT_CONTENT = contentTypes.find(
    (item) => item.key === 'picture',
)!.key;

const SellProductLinkModal = ({
  defaultLink = initialLink,
  editable = true,
  mode = 'add',
  onSave,
  onClose,
  onDelete,
  ...baseModalProps
}: LinkModalProps) => {
  const [link, setLink] = useState<LinksSectionItem>(defaultLink);
  const {t} = useTranslation();
  const {validate: validatePrice} = useMonetizePrice(
      link.currency,
    link.type as MonetizeType,
  );

  const linksUserSections = useSelector(selectLinksUser).linksSections;
  const exclusiveSection = linksUserSections.find(
      (section) => section.type === 'exclusive',
  );


  useEffect(() => {
    setLink({
      ...defaultLink,
      isMonitize: true,
      linksSectionId: exclusiveSection?.id as string,
      currency: defaultLink.currency || DEFAULT_CURRENCY,
    });
    setContentType(DEFAULT_CONTENT);
  }, [defaultLink]);

  const titleChange = (title: string) => {
    if (title.length <= 50) {
      setLink((prevState) => ({...prevState, title}));
    }
  };

  const [contentType, setContentType] = useState<string>(DEFAULT_CONTENT);

  const handleContentTypeChange = (
      e: ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const newValue = e.target.value as string;

    setContentType(newValue);

    setLink((prevState) => ({
      ...prevState,
      attachment: undefined,
      attachmentURL: '',
    }));
  };


  const validNote = link.note && link.note.length > 250;
  const isValid = useMemo(() => {
    return (
      link.title?.length &&
      link?.price?.length &&
      !validatePrice(link.price) &&
      link.currency &&
      link.attachment &&
      (contentType !== 'video' || link.attachmentURL) &&
      !validNote &&
      link?.price?.every((el: any) => el?.amount)
    );
  }, [link]);

  const isOnlyPicture = useMemo(
      () => contentType === 'picture' && (mode !== 'edit' || !link.attachmentURL),
      [contentType, mode, link.attachmentURL],
  );

  const handleLinkBreakdown = (index: number, data: any) => {
    const linkCopy = JSON.parse(JSON.stringify(link));
    linkCopy.price[index] = {...linkCopy.price[index], ...data};
    setLink(linkCopy);
  };

  const [videoUrl, setVideoUrl] = useState(link.attachmentURL);

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <EditableTextInput
        value={link.title!}
        onChange={titleChange}
        placeholder={t('add_title')}
        disabled={!editable}
      />

      {!editable && (
        <Alert style={{fontWeight: 500, fontSize: '1rem'}} severity="warning">
          {t('monetization_is_disabled_for_your_account')}
        </Alert>
      )}

      <EditableImageInput
        value={link.customUploadedImage || ''}
        type={link.type}
        id={link.id}
        onChange={(customUploadedImage) =>
          setLink((prevState) => ({
            ...prevState,
            customUploadedImage,
          }))
        }
        editable={editable}
      />


      {mode === 'add' && (
        <Select
          defaultValue={DEFAULT_CONTENT}
          onChange={handleContentTypeChange}
          disabled={!editable}
          label={t('content_type')}
        >
          {contentTypes.map(({name, key}) => (
            <Select.Item key={key} value={key}>
              {name}
            </Select.Item>
          ))}
        </Select>
      )}

      <div>
        <label className={styles.privateContentLabel}>
          {mode === 'add' ?
            t('upload_private_content') :
            t('download_private_content')}
        </label>
        {!isOnlyPicture && (
          <div style={{paddingBottom: '2rem'}}>
            {contentType === 'video' && !link.attachmentURL && (
              <>
                <div className={styles.videoUrlInput}>
                  <TextInput
                    label="Video URL"
                    value={videoUrl}
                    disabled={!editable}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    containerStyle={{flex: 1}}
                  />
                  <SaveButton
                    onClick={() =>
                      setLink((prevState) => ({
                        ...prevState,
                        attachmentURL: videoUrl,
                      }))
                    }
                    disabled={!isValidHttpUrl(videoUrl) || !editable}
                  >
                    {t('save')}
                  </SaveButton>
                </div>
                <div className={styles.orText}>OR</div>
              </>
            )}
            <UploadButton
              url={link.attachmentURL}
              contentType={mode === 'add' ? contentType : ''}
              onUpload={({url}) => {
                setLink((prevState) => ({
                  ...prevState,
                  attachmentURL: url,
                }));
                setVideoUrl('');
              }}
              label={mode === 'add' ? contentType : ''}
              onDelete={() => {
                setLink((prevState) => ({
                  ...prevState,
                  attachmentURL: '',
                }));
                setVideoUrl('');
              }}
              editable={editable && mode === 'add'}
            />

          </div>
        )}
        <div>
          {!!link.attachment && (
            <div className={styles.imagePreview}>
              <a
                href={link.attachment?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={link.attachment?.url} alt="" />
              </a>
              {editable && (
                <TrashIcon
                  className={styles.deletePicture}
                  onClick={() => setLink((prevLink) => ({...prevLink, attachment: undefined}))}
                />
              )}
            </div>
          )}
          {(!link.attachment &&
            <UploadButton
              url=""
              contentType="picture"
              onUpload={(file) => {
                setLink((prevState) => ({
                  ...prevState,
                  attachment: file,
                }));
              }}
              label={isOnlyPicture ? 'Picture' : 'Cover Picture'}
              onDelete={() => {
                setLink((prevState) => ({
                  ...prevState,
                  attachment: undefined,
                }));
              }}
              editable={editable}
            />

          )}
        </div>
      </div>

      <Select
        value={link.currency}
        onChange={(e) =>
          setLink((prevState) => ({
            ...prevState,
            currency: e.target.value as string,
          }))
        }
        disabled={!editable}
        label={t('currency')}
      >
        {Object.keys(currencies).map((key) => (
          <Select.Item key={key} value={key}>
            {key.toUpperCase()}
          </Select.Item>
        ))}
      </Select>


      <PriceInput
        link={link}
        onChange={(amount) => handleLinkBreakdown(0, {amount})}
        editable={editable}
      />

      <TextInput
        value={link.note}
        onChange={(e) =>
          setLink((prevState) => ({
            ...prevState,
            note: e.target.value,
          }))
        }
        disabled={!editable}
        label={t('give_your_buyer_a_small_teaser')}
        multiline
        minRows={5}
        error={validNote ? t('max_250_characters') : ''}
      />

      <PriceBreakdown link={link} />


      <LinkModal.EditControlsContainer>
        <ToggleButton
          checked={link.isVisible}
          onChange={(checked) =>
            setLink((prevState) => ({
              ...prevState,
              isVisible: checked,
            }))
          }
          label={t('visibility')}
        />


        {mode === 'edit' && (
          <DeleteButton onClick={() => onDelete(link.id)}>
            {t('delete')}
          </DeleteButton>
        )}
      </LinkModal.EditControlsContainer>

      <PrimaryButton
        label={t('save')}
        onClick={() => onSave(link)}
        disabled={!isValid}
        color="red"
        style={{width: '50%', margin: 'auto', padding: '10px 120px'}}
      />
    </BaseModal>
  );
};

export default SellProductLinkModal;
