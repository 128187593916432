import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './charts.modules.scss';
import {useTranslation} from 'react-i18next';
import {isRtl} from '../../features/util';

const LinkClicksChart = ({values, labels}: {values: {name: string, data:number[]}[], labels:string[]}) => {
  const {i18n} = useTranslation();
  const options : ApexCharts.ApexOptions | undefined = {
    chart: {
        type: 'area',
        height: 'auto',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
      },
      xaxis: {
        type: 'category',
        labels: {
          rotate: -90,
          style: {
              cssClass: 'apex-chart-custom-ltr',
          },
        },
        categories: labels,
      },
      yaxis: {
        floating: isRtl(i18n.language) ? true : false,
        opposite: isRtl(i18n.language) ? true : false,
        labels: {
          formatter: function(value) {
            return value?.toLocaleString();
          },
        },
      },
      legend: {
        position: 'bottom',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        colors: ["#E887F9"],
        width: 1,
      },
      fill: {
        type: "gradient",
        gradient: {
            shade: 'dark',
            type: "horizontal",
            shadeIntensity: 0,
            gradientToColors: ["#E887F9"], // optional, if not defined - uses the shades of same color in series
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0.5,
            stops: [0, 1],
        },
      },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}:any) {
        return '<div className="test">' +
        '<p className="serie">' + w.config.series[seriesIndex].name + ' </p>' +
        '<span className="category">' + w.config.series[seriesIndex].data[dataPointIndex].toLocaleString() + '</span>' +
          '</div>';
      },
    },
  };
  return (
    <div className="chart" id="lineChart" style={{height: '300px'}}>
      <ReactApexChart options={options} series={values} type="area" width="100%" height="100%" />
    </div>
  );
};

export default LinkClicksChart;
