import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import defaultStyle from './PublicProfileItemCard.module.scss';
import {socialMediaLinks} from '../../constants/socialMedia';
import defaultIconBlack from '../../assets/images/al_defaultLinkIconBlack.svg';
import defaultIconWhite from '../../assets/images/al_defaultLinkIconWhite.svg';
import videoIcon from '../../assets/images/al_player.svg';
import desktopPreviewStyle from './PublicProfileItemCard_desktop-preview.module.scss';
import mobilePreviewStyle from './PublicProfileItemCard_mobile-preview.module.scss';
import {cardShape, CARD_STYLE, getShapeData, previewStyles, themes} from '../../constants/newThemes';
import useWindowWidth from '../../hooks/useWindowWidth';
import ReactGA from 'react-ga';
import {UserThemeState} from '../../features/stores/userThemeSlicer';
import {determineLinkItemIcon, isArabic} from '../../features/util';
import nonHookRequest from '../../features/API/nonHookRequest';
import {LinksSectionItem} from '../../features/stores/linksUserSlicer';
import {useTranslation} from 'react-i18next';
import {numberFollowerFormat} from '../../Helper/generalHelper';
import currencies, {getCurrencyLabel} from '../../constants/currencies';
import {ReactComponent as CopyIcon} from '../../assets/images/al_copy_icon.svg';
import copy from 'copy-to-clipboard';
import {eventTracking} from '../../services/event-tracking/event-tracking';
import { PrimaryButton } from '../Buttons/PrimaryButton';

interface IPublicProfileItemCard {
  item: LinksSectionItem;
  sectionType: String;
  theme: UserThemeState;
  selectedStyle: string;
  linksUserId: string;
  index: number;
  ownerUserId?: string;
  nickname?: string;
  email?: string;
  setMonetizeID: React.Dispatch<React.SetStateAction<any>>;
  setMonetizeObj?: React.Dispatch<React.SetStateAction<any>>;
  isClicked: boolean;
  copyLinkMessage?: string;
  baseCopyUrl? : string;
}

const PublicProfileItemCard = ({
  linksUserId,
  sectionType,
  selectedStyle,
  item,
  index,
  theme,
  ownerUserId,
  nickname,
  email,
  setMonetizeID,
  setMonetizeObj,
  isClicked,
  copyLinkMessage,
  baseCopyUrl,
}: IPublicProfileItemCard) => {
  const {t, i18n} = useTranslation();
  // const fontFamily = isArabic(i18n.language) ? theme.arabicFont!: theme.englishFont!;
  const fontFamily = theme.englishFont!;
  const width = useWindowWidth();
  let styles: any = '';
  let cardShapeRadius = '' as any;
  let iconRadius = '' as any;
  let edgeCardPadding = '0px';
  let edgeShapeHeight = '80px';
  let videoCardRadius = '0px' as any;

  const shapeData = getShapeData(theme.cardShape!);
  if (selectedStyle === previewStyles.DESKTOP_PREVIEW) {
    styles = desktopPreviewStyle;
    cardShapeRadius = shapeData?.border.desktopPreviewBorderRadius;
    iconRadius = shapeData?.icon.desktopPreviewBorderRadius;
    videoCardRadius = shapeData?.video.desktopPreviewBorderRadius;
    edgeShapeHeight = '18px';
    edgeCardPadding = '4px';
  } else if (selectedStyle === previewStyles.MOBILE_PREVIEW) {
    videoCardRadius = shapeData?.video.mobilePreviewBorderRadius;
    cardShapeRadius = shapeData?.border.mobilePreviewBorderRadius;
    iconRadius = shapeData?.icon.mobilePreviewBorderRadius;
    styles = mobilePreviewStyle;
    edgeShapeHeight='72px';
    edgeCardPadding='10px';
  } else {
    styles = defaultStyle;
    cardShapeRadius = shapeData?.border.borderRadius;
    iconRadius = shapeData?.icon.borderRadius;
    videoCardRadius = shapeData?.video.borderRadius;
    edgeCardPadding = '12px';
    edgeShapeHeight = '80px';
    if (width < 768) {
      edgeCardPadding = '16px';
      edgeShapeHeight = '72px';
    }
  }

  if (theme.cardShape === cardShape.EDGES.name && index % 2 !== 0 && index &&
    (selectedStyle === previewStyles.DESKTOP_PREVIEW ||
      selectedStyle === previewStyles.DEFAULT && width >= 768)) {
    // reverse the border radius to create alternating edges
    cardShapeRadius = cardShapeRadius.split(' ').reverse().join(' ');
    iconRadius = iconRadius.split(' ').reverse().join(' ');
  }

  const img: string = determineLinkItemIcon(item, theme.iconSet!);
  const isCustomImg = !!item.customUploadedImage;
  const videoTitle: any = item.title;

  const handleLinkCount = async () => {
    if (ownerUserId && item.id) {
      await nonHookRequest({method: 'POST', url: '/linkAnalytics/count', body: {userId: ownerUserId, linkId: item.id, nickname: nickname}});
    }
  };

  const handleOpenLink = async () => {
    // const category = linksUserId.toUpperCase();
    // const clicked = item.id.toUpperCase();
    // ReactGA.event({
    //   category: category,
    //   action: clicked,
    // });
    if (!!item?.isMonitize) {
      if (selectedStyle === previewStyles.DEFAULT) {
        setMonetizeID(item.id);
          // TODO: Dynamic currency conversion factor implementation
         setMonetizeObj ? setMonetizeObj({type: item.type, title: item.title, note: item.note ?? '', attachment: item.attachment ?? '', amount: item?.price, url: item?.url || '', currency: item?.currency, maxPrice: (Number(item.maxPrice)/100) || 1000, minPrice: (Number(item.minPrice)/100) || 1, contentType: item?.contentType}) : '';
         eventTracking().track('monetize_link_click', {link_id: item.id, productType: 'Link'});
         handleLinkCount();
      } else {
        toast(<span style={{color: 'black'}}>{'Not active from this section!'}</span>, {autoClose: 3000});
      }
      return;
    }
   
    let url = item.url;
    if (!item.url.match(/^https?:\/\//i)) {
      url = 'http://' + item.url;
    }
    window.open(url, '_blank');
    handleLinkCount();
  };

  useEffect(() => {
    if (isClicked) {
      handleOpenLink();
    }
  }, [isClicked]);

  const handleLinkCopy = () => {
    if (selectedStyle !== previewStyles.DEFAULT) {
      return;
    }
    copy(`${baseCopyUrl}?link=${item.urlSlug}`);
    toast(<span style={{color: 'black'}}>{copyLinkMessage}</span>, {
        autoClose: 3000,
      });
  };

  const defaultCard = (
    <div
      className={styles.container}
      style={{
        backgroundColor: theme.cardColor!,
        boxShadow: theme.boxShadow!,
        fontFamily: fontFamily,
        color: theme.cardTextColor!,
        border: '1px solid ' + theme.borderColor,
        borderRadius: cardShapeRadius,
        ...(theme.cardShape === cardShape.EDGES.name && {height: edgeShapeHeight, padding: edgeCardPadding}),
      }}
      onClick={handleOpenLink}
    >
      <div className={styles.icon_wrapper}>
      <div
        className={styles.icon_container}
        style={{
          backgroundColor: theme.iconSet === 'default' ? theme.cardColor! : theme.backgroundColor!,
          borderRadius: iconRadius ? iconRadius : cardShapeRadius,
        }}
      >
        <img
          className={`${isCustomImg ? styles.default_img : styles.custom_icon}`}
          src={img}
        />
      </div>
      <span
        className={styles.title}
        style={{
          color: theme.cardTextColor!,
          fontFamily: fontFamily,
        }}
      >
        {item.title}
      </span>
      </div>
      <div className={defaultStyle.flexCenter}>
      {!!item.price?.length && <div className={`${styles.monitize} ${item.type == 'tipJar' ? styles.tipjar : ''}`} style={{
        // backgroundColor: 'rgb(74 64 64 / 46%)',
        backgroundColor: item.type != 'tipJar' ? "none" : '#00B3FF',
        color: item.type != 'tipJar' ? theme.cardTextColor! : '#fff',
        whiteSpace: 'nowrap',
        textAlign: 'end',
      }}>{getCurrencyLabel(item.currency)} {item.type != 'tipJar' ? numberFollowerFormat(item?.price[0]?.amount/100) : ''}
      </div>}
      {item.isMonitize && !!item.urlSlug && <div className={styles.copy_monetize_links}
      onClick={(e) => {
        e.stopPropagation();
        handleLinkCopy();
      }}>
      <CopyIcon stroke={theme?.cardTextColor!} />
      </div>}
      </div>
    </div>
  );

  const digitalCard = (
    <div
      className={styles.digital_container}
      style={{
        backgroundColor: theme.cardColor!,
        boxShadow: theme.boxShadow!,
        fontFamily: fontFamily,
        color: theme.cardTextColor!,
        border: '1px solid ' + theme.borderColor,
        borderRadius: cardShapeRadius,
      }}
      onClick={handleOpenLink}
    >
      <div className={styles.digital_content}>
        {/* Icon/Image */}
        <div
          className={styles.icon_container}
          style={{
            backgroundColor: theme.iconSet === 'default' ? theme.cardColor! : theme.backgroundColor!,
            borderRadius: iconRadius ? iconRadius : cardShapeRadius,
          }}
        >
          <img
            className={`${isCustomImg ? styles.default_img : styles.custom_icon}`}
            src={img}
          />
        </div>
        {/* Text Content */}
        <div className={styles.digital_text_wrapper}>
          <span
            className={styles.title}
            style={{
              color: theme.cardTextColor!,
              fontFamily: fontFamily,
            }}
          >
            {item.title}
          </span>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: item.note || '',
            }}
          ></p>
          <div className={styles.price_and_button}>
            <span className={styles.price} style={{ color: theme.cardTextColor! }}>
                Price: {getCurrencyLabel(item.currency)} {item.price ? (item.price[0].amount / 100) : ''}
            </span>
            {/* Buy Now Button */}
            <PrimaryButton
            label={t("Buy now")}
            onClick={handleOpenLink}
            color="black"
            style={{ padding: "4px 24px" }}
          />
          </div>
        </div>
      </div>
    </div>
  );

  const videoCard = (
    <div
      onClick={handleOpenLink}
      className={styles.video_container}
      style={{
        // backgroundColor: theme.cardColor!,
        color: theme.cardTextColor!,
        fontFamily: fontFamily,
        borderRadius: videoCardRadius,
        // border: '1px solid '+ theme.borderColor,
        boxShadow: theme.boxShadow!,
      }}
    >

      <div className={styles.iframe_wrapper}>
        <div className={styles.player_wrapper}>
          <img src={videoIcon} />
        </div>

        <div className={styles.videoDetails}
          style={{
            ...(selectedStyle === previewStyles.DESKTOP_PREVIEW && theme.cardShape !== cardShape.SQUARE.name && {borderRadius: '3px'}),
            ...(selectedStyle !== previewStyles.DESKTOP_PREVIEW && theme.cardShape !== cardShape.SQUARE.name && {borderRadius: '11px'}),
          }}>
          <div

          >
            <img src={item.videoThumbnail!} className={styles.videoThumbnail}/>
          </div>
          <span className={styles.videoDuration}>
            {item.duration}</span>
        </div>
      </div>


      <div className={styles.description_container}>
        {item.channelProfileIcon && <img src={item.channelProfileIcon} className={styles.channel} />}

        <p className={styles.video_title} style={{fontFamily: fontFamily, color: theme.nonCardTextColor!}}>
          {videoTitle}
        </p>
      </div>

    </div>
  );

  let cardContent;

  switch (sectionType) {
    case 'music': {
      cardContent = defaultCard;
      break;
    }
    case 'podcast': {
      cardContent = defaultCard;
      break;
    }
    case 'important_links': {
      cardContent = defaultCard;
      break;
    }
    case 'digital': {
      cardContent = digitalCard;
      break;
    }
    case 'video': {
      cardContent = videoCard;
      break;
    }
    case 'seasonal': {
      cardContent = defaultCard;
      break;
    }
  }

  return <div className={`${sectionType == 'video' ? styles.video_item : ''}`}>{cardContent}</div>;
};

export default PublicProfileItemCard;
