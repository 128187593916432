import React, {useState, useEffect} from 'react';
import styles from './ViewDealDetailsModal.module.scss';
import {useTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import TimerIcon from '@material-ui/icons/TimerOutlined';
import copy from 'copy-to-clipboard';
import {deal, userDeal} from '../../features/stores/dealsSlicer';
import {toast} from 'react-toastify';
import {UserThemeState} from '../../features/stores/userThemeSlicer';
import {calculateTimeLeft, isArabic} from '../../features/util';
import {Tooltip} from 'antd';
import {dealType, dealCategory} from '../../constants/helper';
import {ReactComponent as CopyIcon} from '../../assets/images/al_copy_icon.svg';
import {ReactComponent as ExternalLinkIcon} from '../../assets/images/external_link.svg';
import {themeColor} from '../../constants/newThemes';
import {Switch} from 'antd';
import {PrimaryButton} from '../Buttons/PrimaryButton';
import {truncateUrl} from '../../Helper/generalHelper';
import {ReactComponent as AddIcon} from '../../assets/images/shop/add.svg';
import {AddDealCollectionModal} from './AddDealCollectionModal';
import {ReactComponent as DeleteIcon} from '../../assets/images/shop/delete_rounded.svg';
import nonHookRequest from '../../features/API/nonHookRequest';
import {BaseModalProps} from '../LinkModal/components/BaseModal';
import {raiseToast} from '../Toast/Toast';
import emptyProductImage from '../../assets/images/shop/empty_product.png';
import {ShowCollectionDetailsModal} from './PublicProfileCollection';

type IViewDealDetailsModalProps = BaseModalProps & {
  open: boolean;
  setIsOpen: (arg: boolean) => void;
  userDeal: userDeal;
  deal: deal;
  translates: (key: string) => string;
  theme: UserThemeState;
  isCreator?: boolean
}

const ViewDealDetailsModal = ({
  open, 
  setIsOpen, 
  userDeal,
  deal, 
  translates, 
  theme,
  isCreator,
  onClose,
}: IViewDealDetailsModalProps) =>{
  const [isVisible, setIsVisible] = useState(userDeal?.visible);
  const [addCollectionModalOpen, setAddCollectionModalOpen] = useState(false);
  const [collections, setCollections] = useState<any[]>([]);
  const [openCollectionDetailsModal, setOpenCollectionDetailsModal] = useState(false);
  const [currentCollection, setCurrentCollection] = useState<any>();
  const {t} = useTranslation();

  useEffect(() => {
    if (userDeal?.collection?.length) {
      setCollections(userDeal?.collection);
    }
    setIsVisible(userDeal?.visible);
  }, [userDeal]);

  const handleAddingCollection = (collection: any, resetForm: any) => {
    setCollections((prev) => [collection, ...prev]);
    setAddCollectionModalOpen(false);
    resetForm();
  };

  const handleDeletingCollection = (itemId: number) => {
    setCollections((prev) => prev.filter((item) => item?.id !== itemId));
  };

  const handleSavingChanges = async () => {
    const response = await nonHookRequest({
      url: `/api/v1/user-deals/${userDeal?.id}`,
      method: 'PUT',
      body: {
        visible: isVisible,
        collections: collections?.length ? collections?.map((collection) => {
          return {
            ...(collection?.id ? {id: collection.id} : {}),
            name: collection?.name,
            productIds: collection?.products?.length ? collection?.products?.map((item: any) => item?.product?.id) : [],
          };
        }) : [],
      },
      isShortUrl: true,
      filterError: true,
    });

    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: "Changes Saved Successfully"});
      onClose({}, 'close');
    };
  };

  const handleCopy = (value: string) => {
    copy(value);
    toast(<span style={{color: 'black'}}>{translates('code_copied')}</span>, {autoClose: 3000});
  };

  const getLocalizedText = (theme: UserThemeState, value: number, unit: string) => {
    const language = theme.displayLocale || 'en';
    return isArabic(language) ? `${value} ${translates(unit)} متبقي` : `${value} ${translates(unit)}${value > 1 ? 's' : ''}`;
  };

  const expiry = (endDate: string) => {
    return endDate ? calculateTimeLeft(theme, endDate, getLocalizedText) : translates('limited_time_deal');
  };

  const truncateName = (name: string) => {
    return name?.length > 10 ? name.substring(0, 10) + '...' : name;
  };

  return (
    open ? 
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.modal_content}>
          <div className={styles.cross} onClick={() => setIsOpen(false)}><CloseIcon /></div>
          <div className={styles.content_body}>
            <div className={styles.row_top}>
              <a
                href={userDeal?.dealTrackingLink || "#"} 
                rel="noreferrer" 
                target={userDeal?.dealTrackingLink ? "_blank" : "_self"}
                className={styles.content_image}>
                <img src={deal?.logoUrl || deal?.logoUrl} />
              </a>
              <div className={styles.content}>
                <a
                  href={userDeal?.dealTrackingLink || "#"} 
                  rel="noreferrer" 
                  target={userDeal?.dealTrackingLink ? "_blank" : "_self"}
                  className={styles.content_headline}
                >
                  {deal?.name}
                  <ExternalLinkIcon className={styles.external_icon} />
                </a>
              </div>
            </div>

            <div className={styles.row_middle}>
              {userDeal?.dealTrackingLink && isCreator && deal?.dealType !== dealCategory.COUPON ? 
                <div className={styles.sectionContainer}>
                  <div className={styles.coupon}>{translates('sharing_link')}</div>
                  <div className={styles.coupon_container}>
                    <div className={styles.custom_input}>
                      <p>{userDeal?.dealTrackingLink}</p>
                      <div className={styles.coupon_right}>
                      <CopyIcon 
                        stroke={themeColor.background} 
                        className={styles.actionIcon}
                        onClick={() => handleCopy(userDeal?.dealTrackingLink || '')}
                      />
                      </div>
                    </div>
                  </div>
                </div> : null
              }

              {deal?.dealType !== dealCategory.TRACKING_LINK && deal?.coupon?.length ?
                <div className={styles.sectionContainer}>
                  <div className={styles.coupon}>{translates('coupon_code')}</div>
                  {deal?.coupon?.map((codeItem) => 
                    <div key={codeItem.id} className={styles.coupon_container}>
                      <div className={styles.custom_input}>
                        <p>{codeItem.content}</p>
                        <div className={styles.coupon_right}>
                        {codeItem.endDate && <Tooltip title={expiry(codeItem.endDate)}><TimerIcon htmlColor='orange'></TimerIcon></Tooltip>}
                        <CopyIcon 
                          stroke={themeColor.background} 
                          className={styles.actionIcon}
                          onClick={() => handleCopy(codeItem.content)}
                        />
                        </div>
                      </div>
                    </div>,
                  )}
                </div> : null
              }

              {deal?.dealType !== dealCategory.COUPON && (isCreator || collections?.length) ?
                <div className={styles.sectionContainer}>
                  <div className={styles.coupon}>{translates('collection')}</div>
                  <div className={`${styles.collections} ${styles.customScrollbar}`}>

                    {isCreator ? <div className={styles.dealContainer}>
                      <div className={`${styles.imageContainer} ${styles.AddIcon}`} onClick={() => setAddCollectionModalOpen(true)}>
                        <AddIcon />
                      </div>
                      <div className={styles.textWrapper}>
                        <p className={styles.dealName}>{t('add_collection')}</p>
                      </div>
                    </div> : null}
                    
                    {collections?.length > 0 && collections?.map((collection, i) => {
                      const productsLength = collection?.products?.length;
                      return (
                        <div 
                          key={i} 
                          className={styles.dealContainer}
                        >
                          <div className={styles.imageContainer}>
                            <div 
                              style={{display: 'flex', flexWrap: 'wrap'}} 
                              onClick={() => {
                                setCurrentCollection(collection);
                                setOpenCollectionDetailsModal(true);
                              }}
                            >
                              {collection?.products?.slice(0, 4)?.map((element: any, i: number) => {
                                return (
                                  <img 
                                    src={element?.product?.logoUrl} 
                                    className={styles.smallImage}
                                    alt=""
                                    key={i}
                                  />
                                );
                              })}
                              {collection?.products?.length < 4 && Array(4 - collection?.products?.length).fill(0).map((element, i: number) => (
                                <img 
                                  key={i} 
                                  src={emptyProductImage} 
                                  className={styles.smallImage}
                                  alt=""
                                />
                              ))}
                            </div>
                            <DeleteIcon 
                              style={{position: 'absolute', top: '10px', left: '160px'}} 
                              onClick={() => handleDeletingCollection(collection?.id)} 
                            />
                          </div>
                          <div className={styles.textWrapper}>
                            <p className={styles.dealName}>{truncateUrl(collection?.name, 15)}</p>
                            <p className={styles.productsCount}>{productsLength} {productsLength > 1 ? 'items' : 'item'}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div> : null
              }
            </div>

            <div className={styles.content_text}>
              <label className={styles.how_to}>{translates('how_it_works')}</label>
              <ul>
                <li>{translates('click_brand_name')}</li>
                <li>{translates('brand_discount_coupon')}</li>
              </ul>             
            </div>

            {isCreator ? <div className={styles.footer_wrapper}>
              <div className={styles.title_pencil_wrapper}>
                <Switch
                  // title={dealItem?.userDealContents?.length! > 0 ? '' : t('add_min_one_content')}
                  // disabled={dealItem?.userDealContents?.length! < 1}
                  className={styles.switch}
                  checked={isVisible}
                  onChange={() => setIsVisible((prev) => !prev)}
                />
              </div>

              <PrimaryButton 
                label="Publish"
                onClick={handleSavingChanges}
                style={{width: '180px', height: '35px', margin: '0 15px'}}
                // disabled={!name} 
              />
            </div> : null}

          </div>
        </div>
      </div>
      <AddDealCollectionModal 
        open={addCollectionModalOpen} 
        setIsOpen={setAddCollectionModalOpen} 
        userDeal={userDeal}
        deal={deal}
        handleAddingCollection={handleAddingCollection}
      />
      <ShowCollectionDetailsModal 
        open={openCollectionDetailsModal}
        onClose={() => setOpenCollectionDetailsModal(false)}
        collection={currentCollection}
      />
    </div> : <></>
  );
};

export default ViewDealDetailsModal;
