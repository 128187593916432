import React from 'react';
import style from './CopyLinkCard.module.scss';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {selectMe} from '../../features/stores/userSlicer';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import {useSelector} from 'react-redux';
import defaultAvatar from '../../assets/images/al_avatar_default.png';
import {toast} from 'react-toastify';
import CustomInput from '../CustomInput/CustomInput';
import { ReactComponent as CopyIcon } from '../../assets/images/Version3/icons/content_copy.svg';
import {selectChangeData, setShouldSave} from '../../features/stores/changeDataSlicer';
import copy from 'copy-to-clipboard';
import {useTranslation} from "react-i18next";
import {themeColor} from '../../constants/newThemes';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import useWindowWidth from '../../hooks/useWindowWidth';

// const selectLinksUser = (state: any) => state.user.me.linksUser;
const url = envConfig().copyLinkUrl;
const CopyLinkCard = (): JSX.Element => {
  const {t} = useTranslation();
  const linksUser = useSelector(selectLinksUser);
  const dataChange = useSelector(selectChangeData);
  const handleSave = () => {
    if (dataChange) {
      store.dispatch(setShouldSave(true));
    }
  };
  const mobileView = 768; // Define mobile breakpoint
  const screenWidth = useWindowWidth();

  const handleCopy = () => {
    copy(`${url}/${linksUser?.nickname?.toLowerCase()}`);
    toast(<span style={{ color: 'black' }}>{t('copy_link_success')}</span>, {
      autoClose: 9000,
    });
  };

  return (
    <div className={style.header}>
      <div className={style.input_container}>
        <input
          type="text"
          readOnly
          value={`${url}/${linksUser?.nickname?.toLowerCase()}`}
          className={style.input}
        />
        <div className={style.icon_wrapper} onClick={handleCopy} >
          <CopyIcon />
        </div>
      </div>
      <PrimaryButton
        label={dataChange ? t("save") : t("saved")}
        onClick={handleSave}
        disabled={!dataChange}
        color="red"
        style={{
          padding: screenWidth <= mobileView ? "8px 12px" : "14px 0px",
          fontSize: screenWidth <= mobileView ? "12px" : "inherit",
          minWidth: screenWidth <= mobileView ? 'unset' : '120px',
        }}
      />
    </div>
  );
};

export default CopyLinkCard;
