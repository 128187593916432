import React, {useEffect, useMemo, useState} from 'react';
import styles from './SocialMediaLinks.module.scss';
import {store} from '../../app/store';
import SocialMediaItem from '../SocialMediaItem/SocialMediaItem';
import {extendUrl, socialMediaLinks} from '../../constants/socialMedia';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import {useSelector} from 'react-redux';
import {addSectionItem, deleteLinksSectionItem, LinksSectionItem, selectLinksUser, sortLinksSectionItems, updateSectionItem} from '../../features/stores/linksUserSlicer';
import {reorder} from '../../features/util';
import {useTranslation} from "react-i18next";
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useWindowWidth from '../../hooks/useWindowWidth';
import {themeColor} from '../../constants/newThemes';
import {PlusCircleFilled} from '@ant-design/icons';
import SocialMediaIconModal from '../SocialMediaIconModal/SocialMediaIconModal';
import noLink from '../../assets/images/versionV2/Profile/noLinks.svg';
import {getSocialFilteredData} from '../../Helper/generalHelper';
import { PrimaryButton } from '../Buttons/PrimaryButton';

const ButonCustom = withStyles({
  root: {
    background: (props: any) => props.background,
    color: (props: any) => props.colortext,
    border: (props: any) => props.border ? `1px solid ${props.border}` : 'none',
    boxShadow: 'none',
    borderRadius: '25px',
    marginBottom: ({width}) => width < 768 ? '10px' : '28px',
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '24px',
    padding: '8px 20px',
  },
  contained: {
    '&:hover': {
      background: (props: any) => props.background,
    },
  },
})(Button);

const SocialMediaLinks = () => {
  const {t} = useTranslation();
  const [addModal, setAddModal] = useState(false);
  const linksUser = useSelector(selectLinksUser);
  const linksUserSections = linksUser.linksSections;
  const onBoardFlow = window?.location?.pathname == '/public/claim';
  const mobile = useWindowWidth();
  const socialSectionData = linksUserSections.find(
      (section: any) => section.type === 'social',
  );
  const socialSectionItems = socialSectionData?.linksSectionItems?.filter((e: any) => {
    if (onBoardFlow) {
      return ['tiktok', 'instagram', 'facebook'].includes(e?.type);
    }
    return e?.url || ['instagram', 'tiktok', 'youtube', 'facebook', 'snapchat', 'twitter'].includes(e?.type);
  });
  const allSocialLinkAdded = socialSectionItems?.length == socialSectionData?.linksSectionItems?.length;

  const socialLinkAdded = useMemo(() => getSocialFilteredData(JSON.parse(JSON.stringify(linksUser?.linksSections)), true).filter((e: any) => e?.url?.length), [linksUser?.linksSections]);
  const evaluatedTypeMatch = useMemo(() => {
    return socialLinkAdded?.every((e: any) => {
      const template = socialMediaLinks.filter((ev) => ev.key === e.type)[0].linkTemplates;
      return template.some((evt) => {
        const url = evt?.toLowerCase()?.replace('.', '\.');
        const regex = new RegExp(`^(https:\/\/${url}|https:\/\/www.${url}|${url})`);
        return regex?.test(e?.url?.toLowerCase());
      });
    });
  }, [socialLinkAdded]);

  const handleChosePlatform = (link: any, forceReplace?: boolean) => {
    const obj = forceReplace ? link : {
      id: Date.now(), // id generated via UTC date (unique), so that we can later delete this item (this id is not sent do backend)
      url: '',
      orderNumber:
      socialSectionItems?.length || 0,
      type: link.key,
      placeholder: t(link.placeholder),
      linksSectionId: socialSectionData?.id,
    };
    store.dispatch(setDataChange(true));
    store.dispatch(addSectionItem(obj as unknown as LinksSectionItem));
    // const elementid = selectedItems.length === 0 ? 'sectionTitle' : `element_${selectedItems.length - 1}`;
    // document.getElementById(elementid)?.scrollIntoView({block: 'center', inline: 'center'});

    // was this required?
  };

  const handleUrlChange = (url: string, id: any) => {
    store.dispatch(updateSectionItem({id, data: {url: url}}));
    store.dispatch(setDataChange(true));
  };

  const handleDelete = (item: string) => {
    store.dispatch(updateSectionItem({id: item, data: {url: '', id: (Date.now()).toString()}}));
    store.dispatch(setDataChange(true));
  };

  const handleValidation = (id: string, url: string, type: string) => {
    const newLink = extendUrl({type: type, url: url});
    store.dispatch(updateSectionItem({id: id, data: {url: newLink}}));
    store.dispatch(setDataChange(true));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const itemsTemp = reorder(
        socialSectionItems,
        result.source.index,
        result.destination.index,
    ) as LinksSectionItem[];

    itemsTemp.forEach((element: LinksSectionItem, index: number) => {
      store.dispatch(updateSectionItem({id: element.id, data: {...element, orderNumber: index}}));
    });
    store.dispatch(sortLinksSectionItems());
    store.dispatch(setDataChange(true));
  };

  const disableSave = () => {
    store.dispatch(setDataChange(false));
  };

  useEffect(() => {
    if (!evaluatedTypeMatch) {
      disableSave();
    }
  }, [evaluatedTypeMatch, linksUser?.linksSections]);

  return (
    <>
    {addModal && <SocialMediaIconModal handleChosePlatform={handleValidation} orderNumber={socialSectionItems?.length || 0} handleClose={() => setAddModal(false)} />}
    {/* {!onBoardFlow && !allSocialLinkAdded && mobile > 768 && <ButonCustom onClick={() => setAddModal(true)} width={mobile} className={styles.custom_btn} variant="contained" size={mobile > 1024 ? "large" : "default"} colortext={themeColor.btnColor} background={themeColor.btnBackground} startIcon={<PlusCircleFilled />}>
      <div>{t('add_new_icon')}</div>
    </ButonCustom>} */}
    <div className={styles.drop_container}>
      {socialSectionItems && socialSectionItems?.length ? <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {socialSectionItems?.map((item: LinksSectionItem, index: number) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <SocialMediaItem
                        key={item.id}
                        style={{...(index === 0 && {marginTop: '0'})}}
                        index={index}
                        id={item.id}
                        url={item.url}
                        orderNumber={item.orderNumber}
                        type={item.type}
                        placeholder={item.placeholder}
                        onUrlChange={handleUrlChange}
                        onDelete={handleDelete}
                        onBoardFlow={onBoardFlow}
                        handleValidation={handleValidation}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext> : (
        <div className={styles.no_links}>
        <img src={noLink} />
        <div className={styles.no_links_text}>
        {mobile > 740 ? <h5>{t('you_dont_have_any_icons_to_display')}</h5> : <h6>{t('you_dont_have_any_icons_to_display')}</h6>}
        <div className={styles.subtitle_no_link}>{t(onBoardFlow ? 'click_the_button_below_to_add_one' : 'click_the_button_above_to_add_one')}</div>
        </div>
      </div>
      )}
      {!onBoardFlow && !allSocialLinkAdded && <div className={styles.add_more_btn}>
      <PrimaryButton
        label={t("add_new_icon")}
        onClick={() => setAddModal(true)}
        color="red"
        style={{
          width: mobile <= 768 ? "100%" : "auto",
          padding: mobile > 1024 ? "12px 120px" : "8px 60px",
        }}
      />
      </div>}
    </div>
    </>
  );
};

export default SocialMediaLinks;
