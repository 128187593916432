import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LinksSectionItem} from '../../../features/stores/linksUserSlicer';
import BaseModal from '../components/BaseModal';
import DeleteButton from '../components/DeleteButton';
import EditableImageInput from '../components/EditableImageInput';
import EditableTextInput from '../components/EditableTextInput';
import SaveButton from '../components/SaveButton';
import ToggleButton from '../components/ToggleButton';
import LinkModal, {LinkModalProps} from '../LinkModal';
import currencies from '../../../constants/currencies';
import Select from '../components/Select';
import {initialLink} from './types';
import PriceBreakdown from '../components/PriceBreakdown';
import Alert from '@material-ui/lab/Alert';
import { PrimaryButton } from '../../Buttons/PrimaryButton';

const DEFAULT_PRICE = 5 * 100;
const DEFAULT_CURRENCY = 'usd';

const TipJarLinkModal = ({
  defaultLink = initialLink,
  editable = true,
  mode = 'add',
  onSave,
  onClose,
  onDelete,
  ...baseModalProps
}: LinkModalProps) => {
  const [link, setLink] = useState<LinksSectionItem>(defaultLink);
  const {t} = useTranslation();

  useEffect(() => {
    setLink((prevState) => ({
      ...prevState,
      ...defaultLink,
      price: link?.price[0]?.amount ?? [{type: "default", value: "default", amount: DEFAULT_PRICE}],
      currency: defaultLink.currency ?? DEFAULT_CURRENCY,
      isMonitize: true,
    }));
  }, [defaultLink]);

  const titleChange = (title: string) => {
    if (title.length > 50) return;
    setLink((prevState) => ({
      ...prevState,
      title,
      price: [{type: 'default', value: 'default', amount: DEFAULT_PRICE}],
    }));
  };

  const isValid = useMemo(() => {
    return link.title?.length && link.currency;
  }, [link]);

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <EditableTextInput
        value={link.title!}
        onChange={titleChange}
        placeholder={t('add_title')}
        disabled={!editable}
      />

      {!editable && (
        <Alert style={{fontWeight: 500, fontSize: '1rem'}} severity="warning">
          {t('monetization_is_disabled_for_your_account')}
        </Alert>
      )}

      <EditableImageInput
        value={link.customUploadedImage || ''}
        type={link.type}
        id={link.id}
        onChange={(customUploadedImage) =>
          setLink((prevState) => ({
            ...prevState,
            customUploadedImage,
          }))
        }
        editable={editable}
      />

      <Select
        value={link.currency}
        onChange={(e) =>
          setLink((prevState) => ({
            ...prevState,
            currency: e.target.value as string,
          }))
        }
        disabled={!editable}
        label={t('currency')}
      >
        {Object.keys(currencies).map((key) => (
          <Select.Item key={key} value={key}>
            {key.toUpperCase()}
          </Select.Item>
        ))}
      </Select>

      <PriceBreakdown link={link} />

      <LinkModal.EditControlsContainer>
        <ToggleButton
          checked={link.isVisible}
          onChange={(checked) =>
            setLink((prevState) => ({
              ...prevState,
              isVisible: checked,
            }))
          }
          label={t('visibility')}
        />

        {mode === 'edit' && <DeleteButton onClick={() => onDelete(link.id)}>{t('delete')}</DeleteButton>}
      </LinkModal.EditControlsContainer>

      <PrimaryButton
        label={t('save')}
        onClick={() => onSave(link)}
        disabled={!isValid}
        color="red"
        style={{width: '50%', margin: 'auto', padding: '10px 120px'}}
      />
    </BaseModal>
  );
};

export default TipJarLinkModal;
