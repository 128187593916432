import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Suspense,
  lazy,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import {
  setDataChange,
  setShouldSave,
} from '../../features/stores/changeDataSlicer';
import {getConfig} from './../../features/stores/configSlicer';
import useRequest, {useUploadRequest} from '../../features/API/request';
import RouteLeavingGuard from '../../components/UnsavedChanges/RouteLeavingGuard';
import {useDidUpdateEffect} from '../../hooks/useDidUpdateEffect';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import {refreshMe} from '../../features/Login/login';
import Loader from 'react-loader-spinner';
import WarningModal from '../../components/WarningModal/WarningModal';
import useWindowWidth from '../../hooks/useWindowWidth';
import {raiseToast} from '../../components/Toast/Toast';
import {useTranslation} from 'react-i18next';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {removeStepCookie} from '../../Helper/generalHelper';
import {store} from '../../app/store';
import {haveSegmentPermission, isRtl} from './../../features/util';
import styles from './NewSettings.module.scss';
import UserSettings from '../../components/UserSettings/UserSettings';
import Design from '../Design/DesignV2';
import UpdatePassword from '../AdvanceSettings/UpdatePassword/UpdatePassword';
import {dataURLtoFile} from '../../Helper/imageCropper';
import {toast} from 'react-toastify';
import {selectUserTheme} from '../../features/stores/userThemeSlicer';
import Availability from '../../components/LinkModal/components/Availability';
import Referrals from '../../components/ReferralModal/ReferralModal';
import {useHistory, useLocation} from 'react-router-dom';
import {eventTracking} from '../../services/event-tracking/event-tracking';
import { ReactComponent as DesignIcon } from '../../assets/images/Version3/icons/designIcon.svg';

const MobilePreview = lazy(
  () => import("../../components/MobilePreview/MobilePreview")
);
const MyProfileItem = lazy(
  () => import("../../components/MyProfileItems/MyProfileItem")
);

interface StyleProps {
  width: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    color: '#21000E',
    border: 0,
    boxShadow: "none",
    paddingTop: ({ width }) => (width < 1024 ? "0px" : "80px"),
  },
  tabs: {
    minHeight: "0px",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer": {
      padding: ({ width }) =>
        width < 768 ? "0px 0px 0px 0px" : "0 80px 0 80px",
      "column-gap": ({ width }) => (width < 768 ? "8px" : "10px"),
      "& button": {
        padding: ({ width }) => (width < 768 ? "6px 14px" : "8px 16px"),
        margin: "0 !important",
        "& .MuiTab-wrapper": {
          textTransform: "none",
          fontWeight: 400,
        },
      },
    },
  },
}));

const tabStyle = {
  borderRadius: "32px",
  fontFamily: "'Alexandria', sans-serif",
  height: "35px",
  minHeight: "0",
  minWidth: "auto",
  fontSize: "16px",
  margin: "0 10px 0 10px",
  background: 'white',
  color: "#21000E",
  border: '1px solid #21000E',
};

const tabLabelStyle = (active:any) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: "'Alexandria', sans-serif",
  color: active ? 'white' : '#21000E', // This color will be inherited by the SVG icon
});

const activeStyle: React.CSSProperties = {
  ...tabStyle,
  background: "black",
  color: "white",
};

const designInactiveStyle = {
  ...tabStyle,
  background: "#E887F9", // Customize inactive background color       // Customize inactive text color
};

const getStyle = (active: boolean, index: number, width: number): React.CSSProperties => {
  if (!active && index === 1) { // Assuming Design tab is the second tab (index 1)
    return {
      ...designInactiveStyle,
      margin: width < 768 ? "0 5px" : tabStyle.margin,
    };
  }
  return {
    ...(active ? activeStyle : tabStyle),
    margin: width < 768 ? "0 5px" : tabStyle.margin,
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  width: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, width, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={width < 1024 ? 1 : 3} pr={width < 1024 ? 1 : 3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `full-width-tab-${index}`,
  "aria-controls": `full-width-tabpanel-${index}`,
});

const NewSettings: React.FC = () => {
  const REFERRAL_TAB_INDEX = 4;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const selectUser = (state: any) => state.user.me;
  const user = useSelector(selectUser);
  const theme = useSelector(selectUserTheme);
  const dataChange = useSelector(
    (state: RootState) => state.changeData.changeData
  );
  const shouldSave = useSelector(
    (state: RootState) => state.changeData.shouldSaveEverything
  );
  const config = useSelector(getConfig).data || [];
  const referralsEnabled = haveSegmentPermission(
    config,
    "referralEligibleUsers"
  );
  const linksUser = useSelector(selectLinksUser);
  const [validationFinish, setValidationFinish] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalButtonText, setModalButtonText] = useState("");
  const [value, setValue] = useState(() => {
    let savedTab = localStorage.getItem("activeSettingsTab");
    const urlParams = new URLSearchParams(window.location.search);
    const service = urlParams.get("service");
    if (service == "calendar") {
      savedTab = "3";
    }
    return savedTab ? parseInt(savedTab, 10) : 0;
  });
  const width = useWindowWidth();
  const classes = useStyles({ width });

  const updateProfileRequest = useRequest({
    method: "POST",
    url: "/linksUser/profile-settings",
  });

  const uploadAvatarRequest = useUploadRequest({
    method: "POST",
    url: "/linksUser/user-avatar",
  });

  const themeRequest = useRequest({ url: "/linksUser/theme", method: "POST" });
  const customBackgroundReq = useRequest({
    url: "/linksUser/custom-background-image",
    method: "POST",
  });

  const handleProfileEvent = () => {
    const data: string[] = [];

    const profileChecks = [
      {condition: linksUser.avatar?.includes('base64') && !user.avatar, dataType: 'profile_pic'},
      {condition: linksUser.bio && !user?.linksUser?.bio, dataType: 'bio'},
      {condition: linksUser.fullname && !user?.linksUser?.fullname, dataType: 'name'},
      {condition: linksUser.location && !user?.linksUser?.location, dataType: 'country'},
    ];

    profileChecks.forEach(({condition, dataType}) => {
      if (condition) data.push(dataType);
    });

    if (data.length > 0) {
      eventTracking().track('add_profile_data', {
        data_type: data.join(),
        UUID: user.email,
      });
    }
  };

  const handleSave = useCallback(async () => {
    const result = await themeRequest.doRequest({
      ...theme,
    });
    store.dispatch(setDataChange(false));
    store.dispatch(setShouldSave(false));
  }, [themeRequest, t]);

  const profileContent = <UserSettings />;
  const designContent = <Design handleSave={handleSave} />;

  const SectionList = useMemo(
    () => [
      {
        id: "1",
        key: "account",
        name: t("account"),
        subtitle: t(""),
        isAccordion: false,
        content: profileContent,
        isVisible: true,
        showTitle: false,
      },
      {
        id: "2",
        key: "design",
        name: t("Design"),
        subtitle: t(""),
        isAccordion: false,
        content: designContent,
        isVisible: true,
      },
      {
        id: "3",
        key: "password",
        name: t("pwd"),
        subtitle: t(""),
        isAccordion: false,
        content: <UpdatePassword />,
        isVisible: true,
      },
      {
        id: "4",
        key: "availability",
        name: t("availability"),
        subtitle: t(""),
        isAccordion: false,
        content: <Availability settingView />,
        isVisible: true,
      },
    ],
    [t, handleSave]
  );

  useEffect(() => {
    dispatch(setShouldSave(false));
    removeStepCookie();
  }, [dispatch]);

  useDidUpdateEffect(() => {
    if (shouldSave) {
      validateAllBeforeSave().then((valid) => {
        if (valid && validationFinish && dataChange) {
          saveEverything();
        }
      });
    }
  }, [shouldSave, validationFinish, dataChange]);

  const validateAllBeforeSave = useCallback(async () => {
    setValidationFinish(false);
    const error = false; // Your validation logic here
    if (error) {
      setValidationFinish(false);
      dispatch(setShouldSave(false));
      return false;
    }
    setValidationFinish(true);
    return true;
  }, [dispatch]);

  const saveEverything = useCallback(async () => {
    const currentTab = value;
    const regex = new RegExp(/^[a-z0-9_.]{3,20}$/gm);
    try {
      if (
        linksUser?.nickname != user?.linksUser?.nickname &&
        !regex.test(linksUser.nickname || "")
      ) {
        store.dispatch(setShouldSave(false));
        toast.warning(
          <span style={{ color: "black" }}>{t("username_regex_error")}</span>
        );
        return;
      }
      if (linksUser.avatar?.includes("base64")) {
        const file = dataURLtoFile(linksUser.avatar, `avatar-${linksUser.id}`);
        const params = {
          file: {
            key: "avatar",
            value: file,
            fileName: linksUser.id,
          },
        };

        await uploadAvatarRequest.doRequest(params);
      }
      const payload = {
        bio: linksUser.bio,
        fullname: linksUser.fullname,
        location: linksUser.location,
        displayFollower: linksUser.displayFollower,
        city: linksUser?.city,
        contentCategoryIds: linksUser?.contentCategoryIds || [],
        categoryIds: linksUser?.categoryIds || [],
        phone: linksUser?.phone,
        gender: linksUser?.gender,
      };
      await updateProfileRequest.doRequest(payload);
      handleProfileEvent();
      handleSave();
      raiseToast({ message: t("changes_saved") });
    } catch (error) {
      console.error("Error during saveEverything:", error);
      // Handle errors appropriately here
    } finally {
      dispatch(setShouldSave(false));
      dispatch(setDataChange(false));
      setValidationFinish(false);
      refreshMe();
      setValue(currentTab); // Restore active tab after save
    }
  }, [
    dispatch,
    t,
    value,
    linksUser,
    user,
    uploadAvatarRequest,
    updateProfileRequest,
    handleProfileEvent,
    raiseToast,
    setValidationFinish,
    refreshMe,
    setValue,
  ]);

  const location = useLocation();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    localStorage.setItem("activeSettingsTab", newValue.toString());
    if (newValue === REFERRAL_TAB_INDEX) {
      history.push("/profile/settings/referrals");
    } else {
      history.push("/profile/settings");
    }
  };

  useEffect(() => {
    if (updateProfileRequest.hasError) {
      toast.warning(
        <span style={{ color: "black" }}>{t("username_already_taken")}</span>
      );
    }
  }, [updateProfileRequest.hasError]);

  useEffect(() => {
    if (location.pathname === "/profile/settings/referrals") {
      localStorage.setItem("activeSettingsTab", `${REFERRAL_TAB_INDEX}`);
      setValue(REFERRAL_TAB_INDEX);
    }
  }, [location.pathname]);

  return (
    <div className={styles.container}>
      <RouteLeavingGuard
        when={dataChange}
        navigate={() => "/profile/settings"}
        shouldBlockNavigation={() => true}
        saveFunction={async () => {
          const validationResult = await validateAllBeforeSave();
          if (validationResult) {
            await saveEverything();
            return true;
          }
          return false;
        }}
      />

      <WarningModal
        setIsOpen={setShowModal}
        open={showModal}
        contentHeadline={modalText}
        buttonText={modalButtonText}
      />
      <div className={styles.loadingOnSave}>
        {shouldSave && (
          <Loader type="TailSpin" color="#FF002B" height={80} width={80} />
        )}
      </div>
      <div className={styles.left}>
        <div className={styles.profile_items_container}>
          <div className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              {SectionList.filter((section) => {
                return section.isVisible;
              }).map((section, index) => {
                const isActive = index === value;
                const label = (
                  <span style={tabLabelStyle(isActive)}>
                    {section.key === "design" && (
                      <DesignIcon style={{ marginRight: "6px", color: isActive ? "white" : "#21000E" }} />
                    )}
                    {section.name}
                  </span>
                );
                return (
                  <Tab
                    key={`${section.key}-header`}
                    style={getStyle(index === value, index, width)}
                    label={label}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
            {SectionList.map((section, index) => {
              return (
                <div key={section.key} className={styles.section_container}>
                  {section.isVisible && (
                    <TabPanel
                      width={width}
                      value={value}
                      index={index}
                      key={`${section.key}-item`}
                      dir={isRtl(i18n.language) ? "rtl" : "ltr"}
                    >
                      <MyProfileItem
                        name={section.name as string}
                        id={section.id}
                        subtitle={section.subtitle}
                        content={section.content}
                        isAccordion={section.isAccordion}
                        isVisible={section.isVisible}
                        editableTitle={section.key === "featuredVideos"}
                        showTitle={section.showTitle}
                      />
                    </TabPanel>
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.divider}></div>
        </div>
      </div>
      {![2, 3].includes(value) && (
        <div className={styles.right}>
          <Suspense fallback={<div>Loading...</div>}>
            <MobilePreview showLink={width > 768} />
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default NewSettings;
