import React, {useState, useEffect, useMemo} from 'react';
import useRequest, {useUploadRequest} from '../../features/API/request';
// import logo from '../../assets/images/alfan-link-logo-design-blue.svg';
import logo from '../../assets/images/AlfanBrandNew.svg';
import {store} from '../../app/store';
import styles from './OnboardingV2.module.scss';
import {useTranslation} from 'react-i18next';
import PhoneNumber from '../../components/PhoneNumber/PhoneNumber';
import {useSelector} from 'react-redux';
import {LinksSection, resetLinksUserThunk, selectLinksUser, setLinksUser} from '../../features/stores/linksUserSlicer';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import SelectGender from '../../components/SelectGender/SelectGender';
import AvatarDisplay from '../../components/AvatarDisplay/AvatarDisplay';
import {selectMe} from '../../features/stores/userSlicer';
import CountrySelect from '../../components/CountrySelect/CountrySelect';
import CreatorProfile from '../../components/CreatorProfileV2/CreatorProfile';
import SocialMediaLinks from '../../components/SocialMediaLinks/SocialMediaLinks';
import Loader from 'react-loader-spinner';
import {toast} from 'react-toastify';
import {refreshMe} from '../../features/Login/login';
import {dataURLtoFile} from '../../Helper/imageCropper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Cookies from 'js-cookie';
import {changeBackground, getSocialFilteredData, getUserOnboardingStepTitles, isProdSite, populateApiDataSocial, removeStepCookie} from '../../Helper/generalHelper';
import picBg from '../../assets/images/claimpage.jpg';
import worldMapBg from '../../assets/images/worldmap.jpg';
import SocialMediaBox from '../../components/SocialMediaBox/SocialMediaBox';
import RegionSelect from '../../components/RegionSelect/RegionSelect';
import {completeSignup, sendEvent} from '../../features/util';
import {Link, useHistory} from 'react-router-dom';
import CustomProgressBar from '../../components/CustomProgressBar/CustomProgressBar';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {contentOptionsNewOnBoarding, contentOptionsNewOnBoardingProd, OnboardSteps, tickStepOptions} from '../../constants/helper';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import Favorite from '@material-ui/icons/FavoriteBorder';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {CountryRegionData} from 'react-country-region-selector';
import {creatorActor, eventTracking} from '../../services/event-tracking/event-tracking';

const OnboardingV2 = () => {
    const [step, setStep] = useState(OnboardSteps.GENDER_COUNTRY_CITY_PHONE);
    const [loading, setLoading] = useState(false);
    const [phoneRefreshFlag, setPhoneRefresh] = useState(false);
    const [restart, setRestart] = useState(false);
    const [otherReason, setOtherReason] = useState('');
    const linksUser = useSelector(selectLinksUser);
    const user = useSelector(selectMe).me;
    const {t} = useTranslation();
    const history = useHistory();
    const socialLinkAdded = useMemo(() => getSocialFilteredData(JSON.parse(JSON.stringify(linksUser?.linksSections)), true).filter((e: any) => e?.url?.length), [linksUser?.linksSections]);
    const updateProfileRequest = useRequest({method: 'POST', url: '/linksUser/profile-settings'});
    const phoneFlag = useMemo(() => {
      if (linksUser?.location) {
        setPhoneRefresh(true);
        setTimeout(() => {
          setPhoneRefresh(false);
        }, 500);
        return CountryRegionData?.filter((e) => e[0] === linksUser?.location)[0][1].toLowerCase();
      }
    }, [linksUser?.location]);
    const getHeader = () => {
        switch (step) {
            case OnboardSteps.GENDER_COUNTRY_CITY_PHONE:
              return <><div className={`${styles.head_title} ${styles.text_center}`}>{t('the_nitty_gritty')}</div>
            <div className={`${styles.head_subtitle} ${styles.text_center} ${styles.mr_btm_40}`}>{t('lets_cover_the_basics')}</div></>;
            case OnboardSteps.CREATOR_CATEGORY_PROFILE:
              return <><div className={`${styles.head_title} ${styles.text_center}`}>{t('got_a_superpower')}</div>
            <div className={`${styles.head_subtitle} ${styles.text_center} ${styles.mr_btm_40} ${styles.center_align}`}>
              <div className={`${styles.max_div}`}>
                {t('select_max_three')}
              </div>
            </div></>;
            case OnboardSteps.TICK:
              return <><div className={`${styles.head_title} ${styles.text_center}`}>{t('what_makes_you_tick')}</div>
            <div className={`${styles.head_subtitle} ${styles.text_center} ${styles.mr_btm_40}`}>{t('lets_cover_the_basics')}</div></>;
            default:
              return null;
          }
    };

    const handleStepChange = () => {
      const urlParmas = new URLSearchParams(window.location.search);
      if (restart) {
        return;
      }
      // by default user should see the view which is not filled but incase user itself clicked on back button we have to check for restart
      if (urlParmas?.get('restart') === 'start') {
        setStep(OnboardSteps.GENDER_COUNTRY_CITY_PHONE);
        setRestart(true);
        window.history.pushState({}, document.title, window.location.pathname);
        return;
      }
      if (!user?.gender || !user?.linksUser?.location || !user?.linksUser?.city || !user?.phone || user?.phone?.length < 12) {
        setStep(OnboardSteps.GENDER_COUNTRY_CITY_PHONE);
      } else if (!user?.userContentCategory?.length) {
        setStep(OnboardSteps.CREATOR_CATEGORY_PROFILE);
      } else {
        setStep(OnboardSteps.TICK);
      }
    };

    const handleBackStep = () => {
      switch (step) {
        case OnboardSteps.GENDER_COUNTRY_CITY_PHONE:
          history.push('/public/claim');
          break;
        case OnboardSteps.CREATOR_CATEGORY_PROFILE:
          setStep(OnboardSteps.GENDER_COUNTRY_CITY_PHONE);
          setRestart(true);
          break;
        case OnboardSteps.TICK:
          setStep(OnboardSteps.CREATOR_CATEGORY_PROFILE);
          setRestart(true);
          break;
        default:
          break;
      }
    };

    const getBodyContent = () => {
        switch (step) {
          case OnboardSteps.GENDER_COUNTRY_CITY_PHONE:
            return <div className={styles.page_gender}>
              <div>
                <SelectGender value={linksUser?.gender || ''} handleChange={(e: string)=>{
                  store.dispatch(setLinksUser({...linksUser, gender: e}));
                }} />
              </div>
              <div>
                <div className={styles.component_container}>
                  <CountrySelect />
                </div>
              </div>
              <div>
                <div className={styles.component_container}>
                  <RegionSelect />
                </div>
              </div>
              <div className={styles.phone_conatiner}>
                {!phoneRefreshFlag && <>
                <PhoneNumber value={linksUser?.phone} country={phoneFlag || 'ae'} onChange={(v, c: {dialCode: string}, e) => {
                    const phone = v[0] == '+' ? v.replace(/ /g, '') : '+' + v.replace(/ /g, '');
                    const dialCode = '+' + c?.dialCode;
                    let value = '';
                    if (phone.substring(0, dialCode?.length) == dialCode) {
                    value = phone;
                    } else {
                    value = `${dialCode}${v}`;
                    }
                    store.dispatch(setLinksUser({...linksUser, phone: value}));
                }} />
                </>}
              </div>
            </div>;
          case OnboardSteps.CREATOR_CATEGORY_PROFILE:
            return <CreatorProfile />;
          case OnboardSteps.TICK:
            return <div className={styles.tick_container}>
                {tickStepOptions?.map((option: {[key: string]: string}) => (
                  <div key={option?.key} className={`${styles.tick_box} ${option?.value === linksUser?.whatMakesYouTick ? styles.active : ''}`}>
                    <div className={styles.tick_option_box}>
                        <FormControlLabel
                          control={<Checkbox checked={option?.value === linksUser?.whatMakesYouTick}
                          onChange={() => {
                            if (linksUser?.whatMakesYouTick !== option?.value) {
                              setOtherReason(option?.value);
                              store.dispatch(setLinksUser({...linksUser, whatMakesYouTick: option?.value}));
                            }
                          }}
                          icon={<FiberManualRecordOutlinedIcon />} checkedIcon={<RadioButtonCheckedIcon />} name="checkedH" />}
                          label=""
                        />
                        {t(option.key)}
                    </div>
                    {linksUser?.whatMakesYouTick === 'Other' && option?.value === 'Other' && <div className={styles.text_div}>
                      <TextFieldInput
                                type={'text'}
                                placeholder={t('boost_fan_support')}
                                value={otherReason}
                                onChange={(e: any) => {
                                    setOtherReason(e.target.value);
                                }}
                                customStyle={{padding: '16px 12px 16px'}}
                                staticHeader={false}
                                />
                    </div>}
                  </div>
                ))}
            </div>;
          default:
            return null;
        }
      };

      const handleProfileEvent = (eventName: string) => {
          let data: {[key: string]: any} = {};
          switch (eventName) {
            case 'social_links_added_onboarding':
              data.attribute = [];
              socialLinkAdded.map((e: any) => data.attribute.push({social_channel_name: e?.type, link_of_profile: e?.url}));
              break;
            case 'add_basic_info_onboarding':
              data = {Gender: linksUser?.gender, CountryInfo: linksUser?.location, CityInfo: linksUser?.city, PhoneNo: linksUser?.phone};
              break;
            case 'add_super_power_onboarding':
              const contentList = isProdSite() ? contentOptionsNewOnBoardingProd : contentOptionsNewOnBoarding;
              const contentFilteredList = contentList.filter((e) => linksUser?.contentCategoryIds?.includes(e?.id));
              data.contentType = [];
              contentFilteredList?.map((e) => data?.contentType.push(e?.name));
              break;
            case 'add_interests_onboarding':
              data.interests = otherReason;
              break;
            default:
              break;
          }
          if (Object.keys(data)?.length || eventName) {
            eventTracking(creatorActor).track(eventName, {...data});
          }
      };

      const handleContinue = async () => {
        switch (step) {
          case OnboardSteps.GENDER_COUNTRY_CITY_PHONE:
            try {
              if (!linksUser?.location || !linksUser?.gender || !linksUser?.city || (!linksUser?.phone || (linksUser?.phone && linksUser?.phone?.length < 12))) {
                toast.warning(<span style={{color: 'black'}}>{t('some_data_missing')}</span>);
                return;
              } 
              setLoading(true);
              const payload = {location: linksUser?.location, gender: linksUser?.gender, city: linksUser?.city || '', phone: linksUser?.phone};
                const profileResponse = await updateProfileRequest.doRequest(payload);
                const result = await refreshMe(true);
                if (result && profileResponse) {
                  handleProfileEvent('add_basic_info_onboarding');
                  sendEvent('demographic_confirm');
                  sendEvent('phone_confirm');
                  setStep(OnboardSteps.CREATOR_CATEGORY_PROFILE);
                }
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
            break;
          case OnboardSteps.CREATOR_CATEGORY_PROFILE:
            try {
              if (!linksUser?.contentCategoryIds?.length) {
                toast(<span style={{color: 'black'}}>{t('you_can_select_max_three_superpower')}</span>, {
                  autoClose: 5000,
                });
                return;
              }
              setLoading(true);
              const payload = {bio: linksUser?.bio, fullname: linksUser?.fullname, contentCategoryIds: linksUser?.contentCategoryIds || [], categoryIds: linksUser?.categoryIds || [], whatMakesYouTick: otherReason};
                const updateResponse = await updateProfileRequest.doRequest(payload);
                const result = await refreshMe(true);
                if (result && updateResponse) {
                  handleProfileEvent('add_super_power_onboarding');
                  sendEvent('type_of_content_confirm');
                  setStep(OnboardSteps.TICK);
                  setRestart(false);
                }
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
            break;
          case OnboardSteps.TICK:
            try {
              if (!otherReason) {
                toast(<span style={{color: 'black'}}>{t('select_option')}</span>, {
                  autoClose: 5000,
                });
                return;
              }
              setLoading(true);
              const payload = {bio: linksUser?.bio, fullname: linksUser?.fullname, contentCategoryIds: linksUser?.contentCategoryIds || [], categoryIds: linksUser?.categoryIds || [], whatMakesYouTick: otherReason};
                const updateResponse = await updateProfileRequest.doRequest(payload);
                handleProfileEvent('add_interests_onboarding');
                handleProfileEvent('completed_signup');
                const result = await refreshMe(true);
                if (result && updateResponse) {
                  window.location.href = '/profile/home';
                }
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
            break;
          default:
            break;
        }
      };
    
    useEffect(() => {
        changeBackground('#F4F0ED');
        if (localStorage.getItem('social_links_added_onboarding')) {
          handleProfileEvent('social_links_added_onboarding');
          localStorage.removeItem('social_links_added_onboarding');
        }
    }), [];

    useEffect(() => {
      if (updateProfileRequest?.hasError) {
        toast(<span style={{color: 'black'}}>{t('something_went_wrong')}</span>, {
          autoClose: 5000,
        });
      }
    }, [updateProfileRequest.hasError]);

    useEffect(() => {
      if (user) {
        eventTracking(creatorActor).updateProfile({Email: user.email, Name: user?.linksUser?.fullname});
        handleStepChange();
      }
    }, [user]);

  return (
    <>
      {loading ? <div className={styles['globalLoader']}><Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /></div> : null}
      <div className={styles.bg}>
        <Link className={styles.logo} to="/">
            <span>
              <img src={logo} alt="logo" style={{width: '93px'}} />
            </span>
        </Link>

        <div className={`${styles.main_body_container} ${styles.full_height}`}>
            <div className={styles.main_body_progress_box}>
                <CustomProgressBar
                currentStep={step === OnboardSteps.GENDER_COUNTRY_CITY_PHONE ? 3 : (step === OnboardSteps.CREATOR_CATEGORY_PROFILE ? 4 : 5)}
                stepsName={getUserOnboardingStepTitles(t)} />
            </div>
            <div className={`${styles.main_body_form_box} ${styles.full_width} ${styles.center}`}>
                <div className={styles.language_container}>
                  <LanguagePicker onChange={() => {}} onBoardUi={true} />
                </div>
                <div className={styles.main_body_form_wrapper}>
                    <div>
                        <div>
                        {getHeader()}
                            <form onSubmit={(e)=>e.preventDefault()}>
                                <div>
                                    {getBodyContent()}
                                </div>
                                <div className={styles['btn_wrapper']}>
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        handleBackStep();
                                    }}
                                    className={styles.back_btn}
                                >
                                    <ArrowBackIosIcon />
                                </button>
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        handleContinue();
                                    }}
                                    className={styles['main-btn']}
                                >
                                    {t('next')}
                                </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p className={styles['claim-copy-right']}>{t('claim_cr')}</p>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};


export default OnboardingV2;
