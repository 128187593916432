import React from 'react';
import styles from './PrimaryButton.module.scss';

interface PrimaryButtonProps {
  label: string;
  disabled?: boolean;
  color?: 'red' | 'black';
  onClick: () => void;
  style?: any;
};

export const PrimaryButton = ({label, disabled, color='red', onClick, style}: PrimaryButtonProps) => {
  const hexColor = {
    red: '#FF002B',
    black: '#000',
  };

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={styles.primary_btn}
      style={{boxShadow: `${hexColor[color]} 3.95px 4.95px 0.6px`, border: `2px solid ${hexColor[color]}`, ...style}}
    >
      {label}
    </button>
  );
};
