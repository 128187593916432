import React, {useRef, useState} from 'react';
import {convertBase64ToBlob, blobToBase64} from '../../../Helper/imageCropper';
import {addLinkIcons} from '../../../constants/socialMedia';
import ModalCrop from '../../ModalCrop/ModalCrop';
import {ReactComponent as PencilIcon} from '../../../assets/images/Version3/icons/edit-icon.svg';
import Compressor from 'compressorjs';
import styles from './EditableImageInput.module.scss';

export type EditableImageInputProps = {
  value: string;
  type: string;
  onChange: (value: string) => void;
  editable: boolean;
  id: string;
};
const EditableImageInput = ({value: customImage, onChange, type, editable = true}: EditableImageInputProps) => {
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const oldImageRef = useRef<string | null>(customImage);

  const getImage = () => {
    if (customImage) {
      return <img className={styles.linkIcon} src={customImage} alt='Link Icon' />;
    }

    const LinkIcon = addLinkIcons[type] || addLinkIcons['regularLink'];
    return <LinkIcon className={styles.linkIcon} />;
  };

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const fileSelectedHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl: any = await readFile(e.target.files[0]);
      oldImageRef.current = customImage;
      onChange(imageDataUrl);
      e.target.value = null;
    }
    setIsCropperOpen(true);
  };

  const handleUploadThumbnail = () => {
    inputRef.current?.click();
  };

  return (
    <div>
      <div>
        <input
          ref={inputRef}
          style={{display: 'none'}}
          type='file'
          onChange={fileSelectedHandler}
          disabled={!editable}
        />
        <div className={styles.imageContainer}>
          {getImage()}
          {editable && (
            <div className={styles.imageEditIcon}>
              <PencilIcon className={styles.pencilIcon} onClick={handleUploadThumbnail} />
            </div>
          )}
        </div>
      </div>
      {isCropperOpen && (
        <ModalCrop
          setIsOpen={setIsCropperOpen}
          imgSrc={customImage}
          setImageUrl={(image) => {
            if (image.includes('base64')) {
              new Compressor(convertBase64ToBlob(image), {
                quality: 1,
                maxHeight: 100,
                maxWidth: 100,
                async success(result) {
                  const baseImage = (await blobToBase64(result)) as string;
                  onChange(baseImage);
                },
                error(err) {},
              });
            } else {
              onChange(image);
            }
          }}
          handleCancelUpload={() => {
            onChange(oldImageRef.current ?? '');
          }}
          type='important_links'
        />
      )}
    </div>
  );
};

export default EditableImageInput;
