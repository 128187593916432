import React, {useState, useCallback, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Loader from 'react-loader-spinner';
import {useDebouncedCallback} from 'use-debounce';
import styles from './Deals.module.scss';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import {getDealsStore, setDeals} from '../../features/stores/dealsSlicer';
import {fetchUsersDeals, refreshMe} from '../../features/Login/login';
import nonHookRequest from '../../features/API/nonHookRequest';
import DealItem from './DealItem';
import noVideo from '../../assets/images/versionV2/Profile/noVideo.png';
import {dealsConst} from '../../constants/helper';

interface AvailableDealsProps {
  availableDeals: any[];
  handleGettingUserDeals: () => Promise<void>;
}

const AvailableDeals = ({availableDeals, handleGettingUserDeals}: AvailableDealsProps) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const dealsItems = useSelector(getDealsStore)?.data || [];
  const [searchString, setSearchString] = useState('');
  const [activeId, setActiveId] = useState('');

  const handleDealApply = useCallback(async (id) => {
    try {
      setActiveId(id);
      const response = await nonHookRequest({method: 'POST', url: `/user-deals/apply`, body: {dealId: id}});
      if (response) {
        toast(<span style={{color: 'black'}}>{t('applied_successfully')}</span>, {autoClose: 3000});
        handleGettingUserDeals();
        refreshMe();
        setActiveId('');
      }
    } catch (error) {
      console.error(error);
    }
  }, [t]);

  const handleSearch = useDebouncedCallback((e) => {
    const value = e.target.value;
    setSearchString(value);
    fetchUsersDeals(setDeals, {status: 'Available', search: value});
  }, 200);

  const filteredDeals = useMemo(() => dealsItems.filter((deal) => deal.active), [dealsItems]);

  return (
    <>
      {activeId && (
        <div className={styles.loader}>
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.new_link_wrapper}>
          <input
            className={styles.new_link_input}
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value); // Update state to reflect the current input
              handleSearch(e); // Debounced search handler
            }}
            placeholder={t('search')}
          />
        </div>
        {availableDeals.length ? (
          <div className={styles.scrollable}>
            {availableDeals.map((deal) => (
              <div key={deal.id} className={styles.scrollableItem}>
                <DealItem
                  deal={deal}
                  handleDealApply={() => handleDealApply(deal?.id)}
                  activeId={activeId}
                  status={dealsConst.AVAIALBLE}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.no_links}>
            <img src={noVideo} alt="No deals" />
            <div className={styles.no_links_text}>
              <div className={styles.no_links_title}>{t('no_deals_to_display')}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AvailableDeals;
