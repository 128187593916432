import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import SellProductGuideModal from '../LinkModal/modals/SellProductGuideModal';
import DigitalProductItem from '../DigitalProductItem/DigitalProductItem';
import {
  selectLinksUser,
  LinksSectionItem,
  updateSectionItems,
  deleteLinksSectionItem,
  sortLinksSectionItems,
} from '../../features/stores/linksUserSlicer';
import { getConfig } from '../../features/stores/configSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';
import { LinkIcon } from '../../Helper/getLinkIcon';
import styles from '../ImportantLinks/ImportantLinks.module.scss';
import { addLinkIcons } from '../../constants/socialMedia';
import { setPreviewMode } from '../../features/stores/previewModeSlicer';
import { haveSegmentPermission, reorder } from '../../features/util';
import { setDataChange } from '../../features/stores/changeDataSlicer';
import { desktopVideoSrc, mobileVideoSrc } from '../../constants/helper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DigitalProducts = () => {
  const { t } = useTranslation();
  const mobile = useWindowWidth();
  const history = useHistory();
  const dispatch = useDispatch();

  const config = useSelector(getConfig).data || [];
  const linksUserSections = useSelector(selectLinksUser).linksSections;
  const digitalSection = linksUserSections.find((section) => section.type === 'digital');
  const digitalSectionId = digitalSection?.id || '';
  const digitalLinksItems = digitalSection?.linksSectionItems ?? [];

  const [openLinkModal, setOpenLinkModal] = useState({
    open: false,
    editable: true,
    mode: 'add' as 'add' | 'edit',
    currentItem: null as LinksSectionItem | null,
  });

  if (!digitalSectionId) {
    console.error("Digital section ID is missing.");
    return null;
  }

  const linkLimit = haveSegmentPermission(config, 'maxLinkLimit') || 10;
  const monetizationEnabled = haveSegmentPermission(config, 'monetize') && !haveSegmentPermission(config, 'monetizeBlocklist');

  const segment = haveSegmentPermission(config, 'monetize2')?.map((item: any) => {
    const LinkIcon = addLinkIcons[item.id];
    return {
      type: item.id,
      title: item.title,
      name: t(item.title),
      subTitle: item.subTitle,
      defaultIcon: <LinkIcon />,
    };
  });

  const productItem = segment.map((item: any) => ({
    id: Date.now().toString(),
    type: item?.type ?? 'other',
    url: item?.url ?? '',
    customUploadedImage: '',
    title: item?.name ?? '',
    linksSectionId: digitalSectionId,
    orderNumber: 0,
    hidden: false,
    isVisible: true,
    layout: 'new',
    startDate: item?.startDate,
    endDate: item?.endDate,
    contentType: undefined,
    price: [{ type: "default", value: "default", amount: undefined }],
  })).find((item: any) => item.type === 'sellProductGuide');

  const handleAddProductGuide = () => {
    const newProduct = { ...productItem, id: Date.now().toString() };
    setOpenLinkModal({
      open: true,
      editable: true,
      mode: 'add',
      currentItem: newProduct,
    });
  };

  const handleSave = (link: LinksSectionItem) => {
    let updatedItems;

    if (openLinkModal.mode === 'add') {
      updatedItems = [link, ...digitalLinksItems.map((item) => ({ ...item }))];
    } else {
      updatedItems = digitalLinksItems.map((item) => (item.id === link.id ? link : item));
    }

    updatedItems.forEach((item, index) => {
      item.orderNumber = index;
    });

    dispatch(updateSectionItems({ obj: { linksSectionId: digitalSectionId }, data: updatedItems }));
    dispatch(setDataChange(true));
    setOpenLinkModal((state) => ({ ...state, open: false }));
  };

  const handleDelete = (id: string) => {
    const updatedItems = digitalLinksItems.filter((item) => item.id !== id);
    dispatch(updateSectionItems({ obj: { linksSectionId: digitalSectionId }, data: updatedItems }));
    dispatch(setDataChange(true));
    setOpenLinkModal((state) => ({ ...state, open: false }));
  };

  const handleOpenLinkModal = (link: LinksSectionItem | null, mode: 'add' | 'edit') => {
    setOpenLinkModal({
      open: true,
      editable: true,
      mode,
      currentItem: link,
    });
  };


  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedItems = reorder(
        digitalLinksItems.map((item) => ({ ...item })),
        result.source.index,
        result.destination.index
    );

    reorderedItems.forEach((item, index) => {
      item.orderNumber = index;
    });

    dispatch(updateSectionItems({ obj: { linksSectionId: digitalSectionId }, data: reorderedItems }));
    dispatch(sortLinksSectionItems());
    dispatch(setDataChange(true));
  };

  const handlePreviewClick = async () => {
    await dispatch(setPreviewMode(true));
    history.push('/profile/preview');
  };

  return (
    <div className={styles.container}>
      {openLinkModal.open && (
        <SellProductGuideModal
          editable={openLinkModal.editable}
          defaultLink={openLinkModal.currentItem!}
          open={openLinkModal.open}
          mode={openLinkModal.mode}
          onClose={() => setOpenLinkModal((state) => ({ ...state, open: false }))}
          onSave={handleSave}
          onDelete={(id) => handleDelete(id)}
        />
      )}

      {digitalLinksItems.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="digitalProductsDroppable">
            {(provided) => (
              <div
                className={styles.productGrid}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {digitalLinksItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DigitalProductItem
                          title={item.title || "Digital Product"}
                          contentType={item.contentType}
                          subtitle={item.note || ""}
                          price={item.price || []}
                          icon={<LinkIcon type={item.type} customImage={item.customUploadedImage} />}
                          visibility={item.isVisible}
                          onClick={() => handleOpenLinkModal(item, "edit")}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div className={styles.no_links}>
          <video
            className={styles.no_links_video}
            src={mobile <= 768 ? mobileVideoSrc : desktopVideoSrc}
            autoPlay
            muted
            loop
            playsInline
          />
        </div>
      )}

      <div className={styles.btn_container}>
        {digitalLinksItems.length < linkLimit && (
          <div className={styles.addButton}>
            <PrimaryButton
              label={t("add_link")}
              onClick={handleAddProductGuide}
              color="red"
              style={{ padding: mobile > 1024 ? "12px 120px" : "8px 60px" }}
            />
          </div>
        )}
        {mobile < 768 && (
          <PrimaryButton
            label={t("preview")}
            onClick={handlePreviewClick}
            color="red"
            style={{ padding: mobile > 1024 ? "12px 120px" : "8px 60px" }}
          />
        )}
      </div>
    </div>
  );
};

export default DigitalProducts;
