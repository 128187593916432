import React, {useState, useEffect} from 'react';
import CustomInput from '../CustomInput/CustomInput';
import copyIcon from '../../assets/images/al_copy_icon.svg';
import style from './mobilePreview.module.scss';
import {useSelector} from 'react-redux';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {toast} from 'react-toastify';
import iphone from '../../assets/images/ihpneXFullWidth.png';
import DesktopPreview from '../DesktopPreview/DesktopPreview';
import UserPublicProfile from '../../pages/Public/UserPublicProfileV2/UserPublicProfile';
import {store} from '../../app/store';
import {setShouldSave} from '../../features/stores/changeDataSlicer';
import {selectUserTheme} from '../../features/stores/userThemeSlicer';
import {raiseToast} from '../Toast/Toast';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import CopyLinkCard from '../CopyLinkCard/CopyLinkCard';
import {useTranslation} from "react-i18next";
import {getMusicAlbums} from '../../features/stores/musicAlbumsSlicer';
import {getDealsStore} from '../../features/stores/dealsSlicer';

type MobilePreviewProps = {
  showLink?:boolean;
}

const MobilePreview = ({showLink=true}:MobilePreviewProps) => {
  const {t} = useTranslation();
  const linksUser = useSelector(selectLinksUser);
  const {musicLinks: musicLinks} = useSelector(getMusicAlbums);
  const deals = useSelector(getDealsStore)?.approvedDeals?.filter((e) => e.visible) || [];
  const selectChangeData = (state: any) => state.changeData.changeData;
  const theme = useSelector(selectUserTheme);
  const [selected, setSelected] = useState<'Mobile' | 'Desktop'>('Mobile');
  const url = envConfig().copyLinkUrl;
  const dataChange = useSelector(selectChangeData);

  const handleSave = () => {
    if (dataChange) {
      store.dispatch(setShouldSave(true));
    }
  };

  return <>
    <div className={style.main}>
      {/* {showLink && <div className={style.header}>
        <CustomInput placeholder={'Testni value'} value={`${url}/${linksUser.nickname}`} onChange={(e) => {
        }} className={style.input}>
          <div className={style.icon_wrapper} onClick={() => {
            navigator.clipboard.writeText(`${url}/${linksUser.nickname}`);
            raiseToast({message: 'Link was successfully copied'});
          }}>
            <img src={copyIcon}></img>
            <p>Copy link</p></div>
        </CustomInput>
        <button className={style.button + ' ' + (!dataChange? style.button_disabled : '')} onClick={handleSave}>{dataChange? 'Save' : 'Saved'}</button>
      </div>} */}

      {showLink && <CopyLinkCard/>}
      <div className={style.tabs}>
        <button className={selected === 'Mobile'? style.tabs__selected : ''} onClick={() => setSelected('Mobile')}>{t('mobile')}</button>
        <button className={selected === 'Desktop'? style.tabs__selected : ''} onClick={() => setSelected('Desktop')}>{t('desktop')}</button>
      </div>
    </div>

    {selected === 'Mobile' &&
    <div className={style.mobile_phone}>
      <img className={style.mobile_phone__phone} src={iphone}></img>
      <div className={style.mobile_phone__content}>
        <UserPublicProfile deals={deals} collections={[]} musicLinks={musicLinks} linksUser={linksUser} themeProp={theme} selectedStyle='mobile-preview' />
      </div>
    </div>}
    {selected === 'Desktop' &&
    <div className={style.mobile_phone__content_desktop}>
      <DesktopPreview deals={deals} musicLinks={musicLinks} linksUser={linksUser} themeProp={theme} selectedStyle='desktop-preview'/>
    </div>}
  </>;
};

export default MobilePreview;
