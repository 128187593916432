import React, {useState, useEffect} from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import styles from '../ChargeCardModal/ChargeCardModal.module.scss';
import {isArabic} from '../../features/util';
import greenCheck from '../../assets/images/green-check.svg';
import placeholderImage from '../../assets/images/placeholder.jpg';
import SafeHtmlDisplay from '../LinkModal/components/SafeHtmlDisplay';
import AccessExclusive from '../../assets/images/addLink/monetize/access_exclusive_content.svg';
import AccessExclusiveGuide from '../../assets/images/addLink/monetize/access_exclusive_content_guide.svg';
import {handleDownload} from '../../Helper/utils';

export type LinkSectionItem = {
    title: string;
    type: string;
    note: string;
    attachmentUrl: string;
    thumbUrl: string;
}

interface DetailedSuccessModalContentProps {
    theme?: any
    handleClose: () => void;
    linkSectionItem: LinkSectionItem;
}

export const DetailedSuccessModalContent = ({theme, handleClose, linkSectionItem}: DetailedSuccessModalContentProps) => {
    const [placeholderThumbnail, setPlaceholderThumbnail] = useState(placeholderImage);
    const {t, i18n} = useTranslation();

    useEffect(() => {
      switch (linkSectionItem?.type) {
        case 'sellProductGuide':
          setPlaceholderThumbnail(AccessExclusiveGuide);
          break;
        case 'sellProduct':
          setPlaceholderThumbnail(AccessExclusive);
          break;
        default:
          setPlaceholderThumbnail(placeholderImage);
          break;
      };
    }, [linkSectionItem]);

    const handleCopyingLink = async (link: string) => {
        await navigator.clipboard.writeText(link);
        toast.info(t('copied_to_clipboard'));
    };

    const attachmentUrl = linkSectionItem?.attachmentUrl;

    return (
      <>
        <img src={greenCheck} width={48} height={48} alt=""/>
        <h2 className={styles.success_title}>{t('thank_you')}</h2>
        <p className={styles.success_subtitle}>{t('receive_confirmation_email')}</p>
        <div className={styles.purchase_content}>
          <div className={styles.purchase_info}>
            <img 
              src={linkSectionItem?.thumbUrl || placeholderThumbnail} 
              className={styles.purchase_image} 
              style={isArabic(theme?.displayLocale) ? {marginLeft: '20px'} : {marginRight: '20px'}} 
              alt=""
            />
            <div>
              <a 
                href={attachmentUrl} 
                className={styles.product_link}
                target="_blank" 
                rel="noreferrer"
              >{linkSectionItem?.title}</a>
              <SafeHtmlDisplay htmlContent={linkSectionItem?.note} />
            </div>
          </div>
          <div className={styles.product_link_wrapper}>
            <button 
              className={styles.copy_btn} 
              onClick={() => handleDownload(attachmentUrl)}
            >{t('download')}</button>
          </div>
        </div>
        <button className={styles.home_btn} onClick={handleClose}>{t('return_home')}</button>
      </>
    );
};

interface GenericSuccessModalContentProps {
    theme?: any
}

export const GenericSuccessModalContent = ({theme}: GenericSuccessModalContentProps) => {
    return (
      <>
        <CheckCircleIcon fontSize="large" style={{color: 'green'}} />
        <div style={{textAlign: 'center'}}>
          {isArabic(theme?.displayLocale) ? (
            <>
              شكرًا لك على إكمال طلبك ، سوف تتلقى تحديثًا متعلقًا بطلبك على{' '}
              {new URLSearchParams(window.location.search).get('email')}.
              <br /> إذا كان لديك أي أسئلة أو استفسارات ، يمكنك التواصل معنا
              على{' '}
              <a href="mailto:support@alfangroup.com">
                support@alfangroup.com
              </a>
            </>
          ) : (
            <>
              Thank you for completing your request, you should receive an
              update related to your order on{' '}
              {new URLSearchParams(window.location.search).get('email')}.
              <br /> For any questions or concerns, you can contact us at{' '}
              <a href="mailto:support@alfangroup.com">
                support@alfangroup.com
              </a>
            </>
          )}
        </div>
      </>
    );
};
