import React, {useState, useEffect} from 'react';
import {truncateUrl} from '../../Helper/generalHelper';
import emptyProductImage from '../../assets/images/shop/empty_product.png';
import styles from './PublicProfileCollection.module.scss';
import BaseModal from '../LinkModal/components/BaseModal';
import {BaseModalProps} from '../LinkModal/components/BaseModal';

interface IProps{
  item: any;
  isClicked: boolean;
}

const PublicProfileCollection = ({item, isClicked}: IProps) => {
  return item?.collection ? <RenderCollection collection={item} isClicked={isClicked} /> : <RenderProduct product={item?.product} />;
};

const RenderCollection = ({collection, isClicked}: any) => {
  const [openCollectionDetailsModal, setOpenCollectionDetailsModal] = useState(false);

  useEffect(() => {
    if (isClicked) {
      setOpenCollectionDetailsModal(true);
    }
  }, [isClicked]);

  return (
    <div className={styles.dealContainer}>
      <div className={styles.imageContainer} onClick={() => setOpenCollectionDetailsModal(true)}>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {collection?.collection?.products?.slice(0, 4)?.map((product: any, i: number) => {
            return (
              <img 
                src={product?.product?.logoUrl} 
                className={styles.smallImage}
                alt=""
                key={i}
              />
            );
          })}
          {collection?.collection?.products?.length < 4 && Array(4 - collection?.collection?.products?.length).fill(0).map((element, i: number) => (
            <img 
              key={i} 
              src={emptyProductImage} 
              className={styles.smallImage}
              alt=""
            />
          ))}
        </div>
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.dealName}>{truncateUrl(collection?.collection?.name, 25)}</p>
        <p className={styles.productsCount}>{collection?.collection?.products?.length} {collection?.collection?.products?.length > 1 ? 'items' : 'item'}</p>
      </div>

      <ShowCollectionDetailsModal 
        open={openCollectionDetailsModal}
        onClose={() => setOpenCollectionDetailsModal(false)}
        collection={collection?.collection}
      />
    </div>
  );
};

const RenderProduct = (product: any) => {
  return (
    <div className={styles.dealContainer}>
      <div className={styles.imageContainer}>
        <a href={product?.product?.trackingLink} target="_blank" rel="noreferrer">
          <img
            src={product?.product?.logoUrl}
            style={{width: '100%', height: '100%', cursor: 'pointer', borderRadius: '10px'}}
            alt=""
          />
        </a>
      </div>

      <div className={styles.textWrapper}>
        <p className={styles.dealName}>{truncateUrl(product?.product?.title, 25)}</p>
      </div>
    </div>
  );
};

const PaperProps= {
  style: {
    width: '80%',
    maxWidth: '550px',
    height: '700px',
  },
};

type Props = BaseModalProps & {
  collection: any;
};

export const ShowCollectionDetailsModal = ({collection, onClose, ...props}: Props) => {
  return (
    <BaseModal {...props} PaperProps={PaperProps} onClose={() => onClose({}, 'close')}>
      <div className={styles.container}>
        <h5>{collection?.name}</h5>
        <p className={styles.productsCount}>{collection?.products?.length} {collection?.products?.length > 1 ? 'items' : 'item'}</p>
        <div className={`${styles.dealsContainer} ${styles.customScrollbar}`}>
          {collection?.products?.length && collection?.products?.map((product: any, i: number) => {
            return (
              <RenderProduct product={product?.product || product} key={i} />
            );
          })}
        </div>
      </div>
    </BaseModal>
  );
};

export default PublicProfileCollection;
