import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'antd/dist/antd.css';
import { Pagination, Select, DatePicker } from 'antd';
import styles from './requestDashboard.module.scss';
import RequestTable from '../../components/RequestTable/RequestTable';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import useRequest from '../../features/API/request';
import Loader from 'react-loader-spinner';
import {
  dateRangeOption,
  statusOption,
} from '../../constants/helper';
import { getDateFromRange } from '../../Helper/generalHelper';
import NoTransaction from '../../assets/images/noTransaction.svg';
import TaskCompleteModal from '../../components/TaskCompleteModal/TaskCompleteModal';
import MenuItem from '@material-ui/core/MenuItem';
import FilterIcon from '../../assets/images/versionV2/RequestDashboard/filter_icon.svg';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CheckIcon from '@material-ui/icons/Check';
import UpgradeBanner from '../../components/UpgradeBanner/UpgradeBanner';

const { Option } = Select;
const { RangePicker } = DatePicker;

const RequestDashboard = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const size = 20;
  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // Immediate input value
  const [sort, setSort] = useState('createdAt-DESC');
  const [dates, setDates] = useState<any>([
    moment(getDateFromRange('Last Month')[0]),
    moment(getDateFromRange('Last Month')[1]),
]);
  const [requestList, setRequestList] = useState<any>([]);
  const [orderStatusCount, setOrderStatusCount] = useState<any>([]);
  const [metaRecord, setMetaRecord] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [customRange, setCustomRange] = useState(dateRangeOption[2]);
  const [openTaskCompleteModal, setOpenTaskCompleteModal] = useState(false);
  const [taskIDObj, setTaskIDObj] = useState({ taskID: '', type: '' });
  const [filter, setFilter] = useState<any>([]);
  const [selectedFilterLabel, setSelectedFilterLabel] = useState('All');
  const [openFilter, setOpenFilter] = useState(false);
  const getDashboardRequest = useRequest({
    method: 'GET',
    url: '/finance/api/orders',
    isShortUrl: true,
  });
  const getMetaRequest = useRequest({
    method: 'GET',
    url: '/finance/api/orders/dashboard/summary',
    isShortUrl: true,
  });

  const updatePagination = (page: any) => {
    setCurrent(page);
  };

  // Debounce the API call
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setSearch(searchTerm);
    }, 500); // Adjust delay as needed
    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  const disabledDate = (current: any) => {
    return (
      current
      && moment(current, 'YYYY-MM-DD').isAfter(
          moment().add(1, 'd').format('YYYY-MM-DD'),
      )
    );
  };

  const onCalendarChange = (
      dates: any,
      dateStrings: [string, string],
      info: any,
  ) => {
    if (dateStrings[0].length && dateStrings[1].length) {
      setDates(dateStrings);
      setCustomRange('');
    }
  };

  const handleCustomRange = (range: any) => {
    if (range == 'Custom') {
      setCustomRange('');
      setDates([moment(dates[0]), moment(dates[1])]);
    } else {
      const [start, end] = getDateFromRange(range);
      setCustomRange(range);
      setDates([start, end]);
    }
  };

  const fetchMeta = async () => {
    try {
      setLoading(true);
      const params = {
        startDate: moment(
            `${moment(dates[0]).format('YYYY-MM-DD')} 00:00:00`,
            'YYYY-MM-DD HH:mm:ss',
        )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(
            `${moment(dates[1]).format('YYYY-MM-DD')} 23:59:59`,
            'YYYY-MM-DD HH:mm:ss',
        )
            .utc()
            .format('YYYY-MM-DD HH:mm:ss'),
        productType: 'Link',
      };
      const result = await getMetaRequest.doRequest(params);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Something went wrong!');
    }
  };

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const params = {
        start: (current - 1) * size,
        limit: size,
        sortField: sort.split('-')[0],
        sortOrder: sort.split('-')[1],
        startDate: moment(
            `${moment(dates[0]).format('YYYY-MM-DD')} 00:00:00`,
            'YYYY-MM-DD HH:mm:ss',
        ).utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(
            `${moment(dates[1]).format('YYYY-MM-DD')} 23:59:59`,
            'YYYY-MM-DD HH:mm:ss',
        ).utc().format('YYYY-MM-DD HH:mm:ss'),
        search: search ? search : undefined,
        filters: {
          status: filter.length ? [filter] : undefined,
        },
        productType: 'Link',
      };

      const result = await getDashboardRequest.doRequest(params);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Something went wrong!');
    }
  };

  const updateFilter = (value: string, label: string) => {
    setFilter(value);
    setSelectedFilterLabel(label);
    setOpenFilter(false);
  };


  useEffect(() => {
    if (getMetaRequest?.data) {
      setMetaRecord(getMetaRequest?.data);
    } else {
      setMetaRecord({});
    }
  }, [getMetaRequest.data]);

  useEffect(() => {
    if (getDashboardRequest?.data?.orders?.length) {
      setRequestList(getDashboardRequest?.data?.orders || []);
      setTotal(getDashboardRequest?.data?.totalCount);
      setOrderStatusCount(getDashboardRequest?.data?.ordersStatusCount || []);
    } else {
      setRequestList([]);
      setTotal(0);
      setOrderStatusCount(getDashboardRequest?.data?.ordersStatusCount || []);
    }
  }, [getDashboardRequest.data]);

  useEffect(() => {
    fetchTransactions();
  }, [dates, sort, current, filter, search]);

  useEffect(() => {
    fetchMeta();
  }, [dates]);

  return (
    <div className={styles.request_container}>
      {openTaskCompleteModal && (
        <TaskCompleteModal
          handleClose={(status?: any, attachmentUrl?: any, comments?: any) => {
            setOpenTaskCompleteModal(false);
            if (
              typeof status == 'string'
              && ['Completed', 'Accepted', 'Rejected'].includes(status)
            ) {
              fetchTransactions();
            }
            setTaskIDObj({ taskID: '', type: '' });
          }}
          taskIDObj={taskIDObj}
        />
      )}
      <div className={styles.dashboard_body}>
        {/* <div className={styles.banner}>
          <UpgradeBanner/>
        </div> */}
        <div className={styles.heading_main}>
          <div className={styles.heading_title}>{t('requests_dashboard')}</div>
          <div className={styles.dateRange} id="date-range">
            {customRange ? (
              <Select
                defaultValue={customRange}
                onChange={(e) => handleCustomRange(e)}
              >
                {dateRangeOption.map((e: any) => {
                  return (
                    <Option key={e} value={e}>
                      {t(e)}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              <RangePicker
                bordered={true}
                defaultValue={dates}
                disabledDate={disabledDate}
                onCalendarChange={onCalendarChange}
                placeholder={[t('start_date'), t('end_date')]}
                renderExtraFooter={() => {
                  return (
                    <div className={styles.cal_fotter}>
                      {dateRangeOption
                          .filter((e: any) => e != 'Custom')
                          .map((e: any) => {
                            return (
                              <div
                                onClick={() => handleCustomRange(e)}
                                className={styles.single_date}
                                key={e}
                              >
                                {t(e)}
                              </div>
                            );
                          })}
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
        <>
          <div className={styles.meta_wrapper}>
            <div className={styles.meta_container}>
              <div className={styles.single_meta}>
                <div>
                  <div className={styles.heading}>{t('new_requests')}</div>
                </div>
                <div className={styles.count}>
                  {orderStatusCount.filter(
                      (e: any) => e.status == 'In Review',
                  )?.[0]?.count || '0'}
                </div>
              </div>
              <div className={`${styles.single_meta} ${styles.middle}`}>
                <div>
                  <div className={styles.heading}>{t('open_requests')}</div>
                  {metaRecord?.latestAcceptedOrderDate && (
                    <div className={styles.amount}>
                      <div className={styles.light}>
                        {t('last_request_on')} &nbsp;
                      </div>{' '}
                      <div>
                        {moment(metaRecord.latestAcceptedOrderDate).format(
                            'DD MMM',
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.count}>
                  {orderStatusCount.filter(
                      (e: any) => e.status == 'Accepted',
                  )?.[0]?.count || '0'}
                </div>
              </div>
              <div className={styles.single_meta}>
                <div>
                  <div className={styles.heading}>{t('closed_requests')}</div>
                </div>
                <div className={styles.count}>
                  {orderStatusCount.filter(
                      (e: any) => e.status == 'Completed',
                  )?.[0]?.count || '0'}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.filter_container}>
            <div className={styles.search_container}>
              <div style={{ width: '100%', position: 'relative' }}>
                <OutlinedInput
                  className={styles.search}
                  value={searchTerm} // Shows input immediately
                  onChange={(e) => setSearchTerm(e.target.value)} // Updates searchTerm immediately
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  placeholder="Search"
                />
              </div>

              {/* Filter Button with Custom Dropdown */}
              <div className={styles.filter_button} onClick={() => setOpenFilter(!openFilter)}>
                <span>{selectedFilterLabel}</span>
                <img className={styles.icon} src={FilterIcon} alt="Filter Icon" />
                {openFilter && (
                  <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
                    <div className={styles.dropdown_menu}>
                      {statusOption.map((option: any) => (
                        <MenuItem key={option.value} onClick={() => updateFilter(option.value, option.key)}>
                          <div className={styles.dropdown_option}>
                            <span>{option.key}</span>
                            {filter === option.value && <CheckIcon />}
                          </div>
                        </MenuItem>
                      ))}
                    </div>
                  </ClickAwayListener>
                )}
              </div>

              {/* Custom Dropdown */}

            </div>
          </div>

        </>
        <div className={loading ? styles.loader : ''}>
          {loading ? (
            <Loader type="TailSpin" color="#FF002B" height={80} width={80} />
          ) : requestList.length ? (
            <RequestTable
              requestList={requestList}
              setTaskIDObj={setTaskIDObj}
              setOpenTaskCompleteModal={setOpenTaskCompleteModal}
            />
          ) : (
            <div className={styles.noTransaction}>
              <img src={NoTransaction} />
              <div>{t('no_request_to_display')}</div>
            </div>
          )}
        </div>
        {!!requestList.length && (
          <div className={styles.pagination_container} id="pageSelector">
            <Pagination
              onChange={updatePagination}
              size="small"
              pageSize={size}
              total={total}
              current={current}
              showSizeChanger={false}
              showLessItems={true}
              itemRender={(page, type, originalElement) => {
                return type == 'page' ? (
                  <div className={current == page ? styles.pagiActive : ''}>
                    {page}
                  </div>
                ) : (
                  originalElement
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestDashboard;
