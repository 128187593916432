/* eslint-disable react/prop-types */
import React from "react";
import styles from "./DigitalProductItem.module.scss";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { ReactComponent as DragHandleIcon } from '../../assets/images/Version3/icons/dragIcon.svg';

type PriceType = {
  type: string;
  value: string;
  amount: number;
};

type DigitalProductItemProps = {
  visibility: boolean;
  title: string;
  subtitle?: string;
  contentType?: string;
  price: PriceType[];
  icon?: React.ReactNode;
  onClick: () => void;
};

const DigitalProductItem: React.FC<DigitalProductItemProps> = ({
  visibility,
  title,
  subtitle,
  contentType,
  price,
  icon,
  onClick,
}) => {
  const { t } = useTranslation();

  // Extract and format the first price if available
  const formattedPrice =
    price.length > 0
      ? `$${(price[0].amount / 100).toFixed(2)}`
      : t("Price not available");

  return (
    <div className={styles.mainContainer}>
      <div className={styles.cardContainer}>
      <div className={styles.imageContainer}>
        {icon || <div className={styles.placeholderIcon}>{t("No Image")}</div>}
      </div>
      <div className={styles.content}>
        <span className={styles.category}>{contentType || t("Guide")}</span>
        <h3 className={styles.title}>{title}</h3>
        {subtitle && (
          <div
            className={styles.subtitle}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        <div className={styles.price_edit_section}>
          <p className={styles.price}>
            {t("Price")}:{" "}
            <span className={styles.priceValue}>{formattedPrice}</span>
          </p>
          <PrimaryButton
            label={t("Edit")}
            onClick={onClick}
            color="black"
            style={{ minWidth: '80px' }}
          />
        </div>
      </div>
      </div>
      <div className={styles.dragHandle}>
        <DragHandleIcon />
      </div>
    </div>

  );
};

export default DigitalProductItem;
