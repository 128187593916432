/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint new-cap: ["error", { "capIsNewExceptionPattern": "^window\\.." }] */
import React, {useRef, useEffect} from "react";
import styles from "./Header.module.scss";
import logo from "../../assets/images/alfan-logo.svg";
import {useState} from "react";
import {headerRoutes} from "../../constants/routes";
import {NavLink, useHistory} from "react-router-dom";
import {selectMe} from "../../features/stores/userSlicer";
import {useAppSelector} from "../../app/hooks";
import {store} from "../../app/store";
import defaultAvatar from "../../assets/images/al_default_avatar.png";
import {logOut} from "../../features/Login/login";
import useWindowWidth from "../../hooks/useWindowWidth";
import CopyLinkCard from "../CopyLinkCard/CopyLinkCard";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import {useSelector} from "react-redux";
import {setShouldSave} from "../../features/stores/changeDataSlicer";
import WarningDialog from "../UnsavedChanges/WarningDialog";
import {useTranslation} from "react-i18next";
import {
  getPreferredLocale,
  isRtl,
  haveSegmentPermission,
} from "../../features/util";
import LanguagePicker from "../LanguagePicker/LanguagePicker";
import nonHookRequest from "../../features/API/nonHookRequest";
import {getConfig} from "../../features/stores/configSlicer";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import {getCurrencyFullLabel} from "../../constants/currencies";
import {isProdSite} from "../../Helper/generalHelper";
import SettingsIcon from "@material-ui/icons/Settings";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {makeStyles} from "@material-ui/core/styles";
import PlatformSwitcher from "../PlatformSwitcher/PlatformSwitcher";
import {eventTracking} from "../../services/event-tracking/event-tracking";

const selectChangeData = (state: any) => state.changeData.changeData;
const selectShouldSave = (state: any) => state.changeData.shouldSaveEverything;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "27px",
    width: "auto",
    backgroundColor: "#F4F0ED", // Background color for the bottom nav
    "& .MuiBottomNavigationAction-root": {
      color: "#AA9C9F", // Inactive color for icons and text
      "&.Mui-selected": {
        color: "#FF002B", // Active color for icons and text
      },
      "@media (max-width: 768px)": {
        minWidth: "50px",
        padding: "0",
      },
      "& .MuiBottomNavigationAction-wrapper": {
        "& .MuiBottomNavigationAction-label": {
          fontSize: "12px",
          color: "#AA9C9F",
          "&.Mui-selected": {
            color: "#FF002B", // Active color for text label
          },
          "@media (max-width: 360px)": {
            fontSize: "11px",
          },
        },
      },
    },
    "@media (max-width: 768px)": {
      borderRadius: "0px", // Remove border radius for mobile screens
    },
  },
}));
const Header = () => {
  const {t, i18n} = useTranslation();
  const [active, setActive] = useState(window?.location?.pathname || "");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [clickedLogOut, setClickedLogOut] = useState(false);
  const [earningBalance, setEarningBalance] = useState<any>([]);
  const dataChanged = useSelector(selectChangeData);
  const user = selectMe(useAppSelector(store.getState));
  const config = useSelector(getConfig).data || [];
  const mobileView = 1024;
  const classes = useStyles();
  const node = useRef(null);
  useOutsideAlerter(node, setIsMenuOpen);
  const history = useHistory();
  const linksUser = selectMe(useAppSelector(store.getState)).me.linksUser;
  const userAvatar = linksUser?.avatar ? linksUser.avatar.url : defaultAvatar;
  const mobile = useWindowWidth();
  const transparentAvatarBg =
    active == "" ||
    [
      "/profile/myprofile",
      "/profile/design",
      "/profile/home",
      "/profile/deals",
      "/profile/settings",
      "/profile/profile-icons",
      "/profile/settings/referrals",
      "/",
    ].includes(active);
  const greyedAvatarBg = [
    "/profile/setting/update-password",
    "/profile/setting/contract",
    "/profile/setting/personal-information",
  ].includes(active);
  let headerLinksFiltered =
    haveSegmentPermission(config, "monetize") &&
    !haveSegmentPermission(config, "monetizeBlocklist") ?
      headerRoutes :
      headerRoutes.filter(
          (e: any) => e.key != "requestdashboard",
        );
  headerLinksFiltered =
        haveSegmentPermission(config, "referralEligibleUsers") ?
        headerLinksFiltered :
          headerRoutes.filter(
              (e: any) => e.key != "referrals",
            );
  const currencyMap = haveSegmentPermission(config, "currencyFactor") || [];
  if (!!!user?.me?.inNetwork || isProdSite()) {
    headerLinksFiltered = headerLinksFiltered.filter(
      (e: any) => e.key != "network",
    );
  }

  const setPathActivation = (match: unknown, pathname: string) => {
    if (pathname === "/") {
      setActive("/profile/home");
      return !!match;
    }

    if (pathname === "/profile/settings/referrals") {
      setActive("/profile/settings/referrals");
      return !!match;
    }

    if (['/profile/home', '/profile/profile-icons'].includes(pathname)) {
      setActive('/profile/home');
      return !!match;
    }

    if (pathname.includes("/profile/request-dashboard")) {
      setActive("/profile/request-dashboard");
      return !!match;
    }

    setActive(pathname);
    return !!match;
  };


  const openRoute = (url: string) => {
    window.location.href = url;
  };

  const headerLinks = headerLinksFiltered.map((route: any) => {
    const activeRoute =
      route.route === active ||
      (active && active.includes(route?.pathMatch)) ||
      (route.activatedRoute && route.activatedRoute.includes(active));

    return (
      <div
        className={`${styles.route} ${activeRoute ? styles.active : ""}`}
        key={route.key}
      >
          <NavLink
            onClick={route.onClick}
            to={route.route}
            className={styles.route}
            activeClassName={route.key == 'referrals' ? '': styles.route_active}
            isActive={(match, location) =>
              setPathActivation(match, location?.pathname)
            }
          >
            <img
              src={activeRoute ? route.activeIcon : route.icon}
              alt={route.key}
              className={styles.route_img}
            />
            <p
              className={
                activeRoute ? styles.route_active : styles.route_description
              }
            >
              {t(route.name)}
            </p>
          </NavLink>
      </div>
    );
  });

  const getEarning = async () => {
    try {
      const earningResponse = await nonHookRequest({
        method: "GET",
        url: "/finance/api/wallet",
        body: {},
        isShortUrl: true,
      });
      if (earningResponse) {
        setEarningBalance(earningResponse);
      }
    } catch (error) {
      console.log("something went wrong!");
    }
  };

  const showSupportWidget = () => {
    if (window.Trengo && window.Trengo.Api && window.Trengo.Api.Widget) {
      window.Trengo.Api.Widget.open('chat');
    }
  };

  useEffect(() => {
    if (active && mobile <= mobileView) {
      if (active.includes("/earnings/dashboard")) {
        window.location.href = active;
      } else {
        history.push(`${active}${window.location.search}`);
      }
    }
  }, [active]);

  useEffect(() => {
    if (user.loginStatus == "LOGGED_IN") {
      setTimeout(() => getEarning());
    }
  }, [user]);

  return (
    <>
      {dataChanged && (
        <WarningDialog
          key={Date.now()}
          open={clickedLogOut}
          titleText={t("close_without_saving")}
          contentText={t("unsaved_changes_warning")}
          cancelButtonText={t("save_changes")}
          confirmButtonText={t("discard_changes")}
          onCancel={() => {
            store.dispatch(setShouldSave(true));
            setTimeout(() => {
              logOut();
              // window.location.href='/';
            }, 500);
          }}
          onConfirm={() => {
            logOut();
            // window.location.href='/';
          }}
          onModalClose={() => {}}
        />
      )}
      {mobile > mobileView && (
        <div className={styles.container}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <PlatformSwitcher />
            {mobile > mobileView && (
              <div className={styles.routes_container}>{headerLinks}</div>
            )}
          </div>
          <div
            className={`${styles.right_side} ${
              transparentAvatarBg ? styles.blur_bg : ""
            } ${greyedAvatarBg ? styles.grey_bg : ""}`}
          >
            <LanguagePicker
              onChange={() => {
                eventTracking().updateProfile({
                  Language: getPreferredLocale(),
                });
              }}
              newUi={true}
            ></LanguagePicker>
            <div
              className={styles.loggedUser_container}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <img className={styles.avatar} src={userAvatar} />
              <div className={styles.names_container}>
                {linksUser?.fullname}
              </div>
              {isMenuOpen && (
                <div
                  className={styles.logout_menu}
                  style={
                    isRtl(i18n.language) ? {left: "43px"} : {right: "43px"}
                  }
                  ref={node}
                >
                  <div onClick={showSupportWidget}>
                    <ContactSupportOutlinedIcon fontSize="large" />
                    <p>{t("support")}</p>
                  </div>
                  <NavLink to="/profile/settings">
                    <div onClick={() => {}}>
                      <SettingsIcon fontSize="large" />
                      <p>{t("advanced")}</p>
                    </div>
                  </NavLink>
                  <div
                    onClick={() => {
                      if (dataChanged) {
                        setClickedLogOut(true);
                      } else {
                        logOut();
                      }
                    }}
                  >
                    <ExitToAppOutlinedIcon fontSize="large" />
                    <p>{t("logout")}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {mobile <= mobileView && (
        <>
          <div className={styles.copy_link}>
            <div className={styles.mobile_head_user}>
              <PlatformSwitcher />
              <CopyLinkCard />
            </div>

          </div>
          <div className={styles.bottom_navigation}>
            <BottomNavigation
              value={active}
              onChange={(event: React.ChangeEvent<{}>, path: string) =>
                setPathActivation(null, path)
              }
              className={classes.root}
            >
              {headerLinksFiltered.map((route: any) => (
                <BottomNavigationAction
                  showLabel
                  key={route.route}
                  label={t(route.name)}
                  value={route.route}
                  icon={
                    <img
                      src={
                        route.route === active ||
                        active?.includes(route?.pathMatch) ||
                        route?.activatedRoute?.includes(active) ?
                          route.mobileIcon :
                          route.icon
                      }
                      alt={route.key}
                      className={styles.route_img}
                    />
                  }
                />
              ))}
            </BottomNavigation>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
