import React, { useEffect, useState, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";

import styles from "./Home.module.scss";
import useRequest, { useUploadRequest } from "../../features/API/request";
import { selectLinksUser } from "../../features/stores/linksUserSlicer";
import { getConfig } from "../../features/stores/configSlicer";
import {
  setDataChange,
  setShouldSave,
} from "../../features/stores/changeDataSlicer";
import { haveSegmentPermission } from "../../features/util";
import { store } from "../../app/store";
import {
  numberFollowerFormat,
  removeStepCookie,
} from "../../Helper/generalHelper";
import useWindowWidth from "../../hooks/useWindowWidth";
import AvatarDisplay from "../../components/AvatarDisplay/AvatarDisplay";
import { dynamicSocials } from "../../constants/socialMedia";

import Profile from "../../assets/images/Version3/icons/ProfileIcon.svg";
import Notification from "../../assets/images/versionV2/HelperIcons/notification.svg";
import { useDidUpdateEffect } from "../../hooks/useDidUpdateEffect";
import { raiseToast } from "../../components/Toast/Toast";
import { refreshMe } from "../../features/Login/login";
import { dataURLtoFile } from "../../Helper/imageCropper";
import {eventTracking} from '../../services/event-tracking/event-tracking';

// Lazy load components
const MobilePreview = lazy(
  () => import("../../components/MobilePreview/MobilePreview")
);
const WalletHome = lazy(() => import("../../components/WalletHome/WalletHome"));
const TotalRevenue = lazy(
  () => import("../../components/TotalRevenue/TotalRevenue")
);
const LinkClicks = lazy(
  () => import("../Analytics/Components/LinkClicks/LinkClicks")
);
const Followers = lazy(
  () => import("../Analytics/Components/Followers/Followers")
);
const DemographicVisits = lazy(
  () => import("../Analytics/Components/DemographicVisits/DemographicVisits")
);
const ProfileVisits = lazy(
  () => import("../Analytics/Components/ProfileVisits/ProfileVisits")
);
const MusicLinkClicks = lazy(
  () => import("../Analytics/Components/LinkClicks/MusicLinkClicks")
);
const MusicLinkStatsBreakdown = lazy(
  () => import("../Analytics/Components/LinkClicks/MusicLinkClicksBreakdown")
);
const DealsClickStats = lazy(
  () => import("../Analytics/Components/Deals/DealsClick")
);
const DealsTotal = lazy(
  () => import("../Analytics/Components/Deals/DealsTotal")
);
const ClickOnLinks = lazy(
  () => import("../Analytics/Components/ClickOnLinks/ClickOnLinks")
);
const OrderTotal = lazy(
  () => import("../Analytics/Components/OrderTotal/OrderTotal")
);

interface StyleProps {
  width: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: 0,
    boxShadow: "none",
    paddingTop: "0px",
  },
}));

const selectShouldSave = (state: any) => state.changeData.shouldSaveEverything;
const selectChangeData = (state: any) => state.changeData.changeData;
const selectUser = (state: any) => state.user.me;

const Home = () => {
  const { t } = useTranslation();
  const shouldSave = useSelector(selectShouldSave);
  const dataChange = useSelector(selectChangeData);
  const linksUser = useSelector(selectLinksUser) as any;
  const config = useSelector(getConfig).data || [];
  const haveEarningPermission =
    haveSegmentPermission(config, "monetize") &&
    !haveSegmentPermission(config, "monetizeBlocklist");
  const user = useSelector(selectUser);

  const [stats, setStats] = useState({ clicks: 0, followers: 0, orders: 0 });
  const width = useWindowWidth();
  const uniqueFollowerCount = linksUser?.displayFollower
    ? numberFollowerFormat(linksUser?.followersCount || 0, 1)
    : 0;
  const classes = useStyles({ width });

  const uploadAvatarRequest = useUploadRequest({
    method: "POST",
    url: "/linksUser/user-avatar",
  });

  const uploadLinksRequest = useRequest({
    method: "POST",
    url: "/linksSectionItem/section-items",
  });

  const userSocialMediaLinks = linksUser?.linksSections
    ?.find((section: any) => section.type === "social")
    ?.linksSectionItems?.filter((e: any) => e?.url)
    ?.slice()
    .sort((a: any, b: any) => a.orderNumber - b.orderNumber);

  const socials = dynamicSocials("#000000");
  const socialMediaData = userSocialMediaLinks?.map(
    (item: any, index: number) => {
      const dynamicData = socials.filter((soc: any) => soc.key === item.type);
      if (dynamicData.length) {
        return (
          <div key={`social-icon-link-${index}`} className={styles.mediaIcons}>
            {dynamicData[0].dynamic}
          </div>
        );
      }
      return null;
    }
  );

  const updateStats = (value: any) => {
    setStats((prevState: any) => ({ ...prevState, ...value }));
  };

  const handleProfileEvent = () => {
    if (linksUser.avatar?.includes('base64') && !user.avatar) {
      eventTracking().track('add_profile_data', {data_type: 'profile_pic', UUID: user.email});
    }
  };

  const saveEverything = async () => {
    if (linksUser.avatar?.includes("base64")) {
      const file = dataURLtoFile(linksUser.avatar, `avatar-${linksUser.id}`);
      const params = {
        file: {
          key: "avatar",
          value: file,
          fileName: linksUser.id,
        },
      };
      await uploadAvatarRequest.doRequest(params);
      handleProfileEvent();
      raiseToast({ message: t("changes_saved") });
      store.dispatch(setShouldSave(false));
      store.dispatch(setDataChange(false));
      refreshMe();
    }
    return true;
  };

  useEffect(() => {
    store.dispatch(setShouldSave(false)); // clear state
    removeStepCookie();
    // handleEvent();
  }, []);

  useDidUpdateEffect(async () => {
    if (shouldSave && dataChange) {
      saveEverything();
    }
  }, [shouldSave]);

  return (
    <div className={styles.container}>
      <div className={styles.loadingOnSave}>
        {(shouldSave ||
          uploadAvatarRequest.isFetching ||
          uploadLinksRequest.isFetching) && (
          <Loader type="TailSpin" color="#FF002B" height={80} width={80} />
        )}
      </div>
      <div className={styles.left}>
        <div className={styles.profile_items_container}>
          <div className={`${classes.root} ${styles.left_container}`}>
            <div className={styles.avatar}>
              <AvatarDisplay />
            </div>
            <div className={styles.user_data_box}>
              <div className={styles.user_data_title_row}>
                <div className={styles.username}>{linksUser?.fullname}</div>
                <div className={styles.icon_box}>
                  <Link to="/profile/request-dashboard">
                    <img src={Notification} alt="Notification" />
                  </Link>
                  <Link to="/profile/settings">
                    <img src={Profile} alt="Profile" />
                  </Link>
                </div>
              </div>
              {uniqueFollowerCount != "0" && (
                <div className={styles.user_follower_row}>
                  <div className={styles.follower}>{uniqueFollowerCount}</div>
                  <div className={styles.follower_title}>
                    {t("total_followers")}
                  </div>
                </div>
              )}
              {userSocialMediaLinks?.length ? (
                <div className={styles.user_social_row}>
                  <div className={styles.icons_set}>{socialMediaData}</div>
                  <Link to="/profile/profile-icons">
                    <div className={styles.icon_edit}>{t("edit")}</div>
                  </Link>
                </div>
              ) : (
                <div className={styles.user_social_row}>
                  <Link to="/profile/profile-icons">
                    <div className={styles.icon_edit}>
                      {t("add_social_media")}
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className={styles.graph_container}>
          <TotalRevenue />
            <Suspense fallback={<div>Loading...</div>}>
              {!!haveEarningPermission && (
                <div className={styles.wallet_box}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <WalletHome />
                  </Suspense>
                </div>
              )}
              <div>
                <div className={styles.analytics_header}>
                  {t("my_analytics")}
                  <div className={styles.analytics_header_subtitle}>
                    ({t("last_30_days")})
                  </div>
                </div>
                <div className={styles.analytics_stats_container}>
                  <div className={styles.analytics_stats_box}>
                    <div className={styles.title}>{t("clicks")}</div>
                    <div className={styles.stats}>{stats?.clicks}</div>
                  </div>
                  <div className={styles.analytics_stats_box}>
                    <div className={styles.title}>{t("followers")}</div>
                    <div className={styles.stats}>{stats.followers}</div>
                  </div>
                  <div className={styles.analytics_stats_box}>
                    <div className={styles.title}>{t("order")}</div>
                    <div className={styles.stats}>{stats?.orders}</div>
                  </div>
                </div>
                <div className={styles.analytics_wrapper_entity}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Followers updateStats={updateStats} />
                    <ProfileVisits />
                    <LinkClicks updateStats={updateStats} />
                    <ClickOnLinks />
                    <DemographicVisits />
                    <OrderTotal updateStats={updateStats} />
                  </Suspense>
                </div>
              </div>
              <div>
                <div className={styles.analytics_header}>{t("music")}</div>
                <div className={styles.analytics_wrapper_entity}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <MusicLinkClicks
                      previewPlay={false}
                      title={t("clicks_and_plays")}
                    />
                    <MusicLinkStatsBreakdown
                      previewPlay={false}
                      title={t("clicks_per_album")}
                    />
                    <MusicLinkStatsBreakdown
                      previewPlay={true}
                      title={t("plays_per_album")}
                    />
                  </Suspense>
                </div>
              </div>
              <div>
                <div className={styles.analytics_header}>{t("deals")}</div>
                <div className={styles.analytics_wrapper_entity}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <DealsTotal />
                    <DealsClickStats />
                  </Suspense>
                </div>
              </div>
            </Suspense>
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <Suspense fallback={<div>Loading...</div>}>
          <MobilePreview showLink={width > 768} />
        </Suspense>
      </div>
    </div>
  );
};

export default Home;
