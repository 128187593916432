import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LinksSectionItem} from '../../../features/stores/linksUserSlicer';
import BaseModal from '../components/BaseModal';
import DeleteButton from '../components/DeleteButton';
import EditableImageInput from '../components/EditableImageInput';
import EditableTextInput from '../components/EditableTextInput';
import SaveButton from '../components/SaveButton';
import ToggleButton from '../components/ToggleButton';
import LinkModal, {LinkModalProps} from '../LinkModal';
import currencies from '../../../constants/currencies';
import Select from '../components/Select';
import {MonetizeType, initialLink} from './types';
import PriceBreakdown from '../components/PriceBreakdown';
import PriceInput, {useMonetizePrice} from '../components/PriceInput';
import Alert from '@material-ui/lab/Alert';
import styles from './ShortCallLinkModal.module.scss';
import {toast} from 'react-toastify';
import nonHookRequest from '../../../features/API/nonHookRequest';
import Loader from 'react-loader-spinner';
import {fetchUserSchedule} from '../../../features/Login/login';
import {useSelector} from 'react-redux';
import {getSchedule} from '../../../features/stores/scheduleSlicer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {getGoogleCalender} from '../../../features/stores/googleCalendarSlicer';
import GoogleCalendarAuth from '../components/GoogleCalendarAuth';
import TextInput from '../components/TextInput';
import {SectionItemContentType} from '../../../constants/helper';
import { PrimaryButton } from '../../Buttons/PrimaryButton';
import AvailabilityModal from '../../AvailabilityModal/AvailabilityModal';

const DEFAULT_CURRENCY = 'usd';

const ShortCallLinkModal = ({
  defaultLink = initialLink,
  editable = true,
  mode = 'add',
  onSave,
  onClose,
  onDelete,
  ...baseModalProps
}: LinkModalProps) => {
  const [link, setLink] = useState<LinksSectionItem>(defaultLink);
  const {t} = useTranslation();
  const schedule = useSelector(getSchedule).data || {};
  const calendarConnected = useSelector(getGoogleCalender).connected || false;
  const {validate: validatePrice} = useMonetizePrice(link.currency, link.type as MonetizeType);
  const [scheduledChange, setScheduleChange] = useState<any>({change: false, slots: {}, activeDay: [], timezone: '', notValid: false});
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const validNote = link.note && link.note.length > 250;
  const googleAuthComponent = <GoogleCalendarAuth />;

  useEffect(() => {
    const data: any = {currency: defaultLink.currency || DEFAULT_CURRENCY};
    if (!defaultLink?.price?.length) {
      data.price = [{value: '15', amount: undefined, type: 'minute'}];
    }
    setLink((prevState) => ({
      ...prevState,
      ...defaultLink,
      ...data,
      isMonitize: true,
      contentType: SectionItemContentType.GOOGLE_CALENDAR,
    }));
  }, [defaultLink]);

  const titleChange = (title: string) => {
    if (title.length <= 50) {
      setLink((prevState) => ({
        ...prevState,
        title,
      }));
    }
  };

  const everySlotSelected = () => {
    return link?.price?.every((el: any) => el?.value && el?.amount && el?.amount > 0);
  };

  const isValid = useMemo(() => {
    return (
      link.title?.length &&
      !validatePrice(link.price) &&
      everySlotSelected() && 
      link.currency &&
      scheduledChange.activeDay.length > 0 
    );
  }, [link, scheduledChange.activeDay, validatePrice]);

  const handleAddDuration = () => {
    setLink((prevState) => {
      const linkCopy = JSON.parse(JSON.stringify(prevState));
      linkCopy.price = [...linkCopy.price, {value: '', amount: undefined, type: 'minute'}];
      return linkCopy;
    });
  };

  const handleDurationChange = (e: any, index: number) => {
    if (e.target.value) {
      setLink((prevState) => {
        const linkCopy = JSON.parse(JSON.stringify(prevState));
        linkCopy.price[index] = {...linkCopy.price[index], value: e.target.value};
        return linkCopy;
      });
    }
  };

  const handleLinkBreakdown = (index: number, data: any) => {
    setLink((prevState) => {
      const linkCopy = JSON.parse(JSON.stringify(prevState));
      linkCopy.price[index] = {...linkCopy.price[index], ...data};
      return linkCopy;
    });
  };

  const handleDelete = (index: number) => {
    setLink((prevState) => {
      const linkCopy = JSON.parse(JSON.stringify(prevState));
      linkCopy?.price?.splice(index, 1);
      return linkCopy;
    });
  };

  const handleChange = (data: any) => {
    setScheduleChange({...scheduledChange, ...data});
  };

  const handleScheduleUpdate = () => {
    if (!scheduledChange?.activeDay?.length) {
      toast.error(<span style={{color: 'white'}}>{t('no_slot_selected')}</span>);
    } else if (!scheduledChange.timezone) {
      toast.error(<span style={{color: 'white'}}>{t('select_timezone')}</span>);
    } else if (scheduledChange?.change) {
      setOpenAlertModal(true);
    } else {
      onSave(link);
    }
  };

  const saveSlots = async () => {
    try {
      setLoading(true);
      const payload: {[key: string]: any} = {slots: [], timezone: scheduledChange?.timezone};
      Object.keys(scheduledChange.slots).map((e) => {
        scheduledChange.slots[e].map((el: any) => {
          payload.slots.push({"dayOfWeek": e, "startTime": el.startTime, "endTime": el.endTime});
        });
      });
      const calenderResponse = await nonHookRequest({method: schedule?.id ? 'PUT' : 'POST', url: '/calendar-availability', body: payload});
      fetchUserSchedule();
      if (calenderResponse) {
        toast(<span style={{color: 'black'}}>{t('slots_updated')}</span>, {autoClose: 3000});
        onSave(link);
        setOpenAlertModal(false);
      }
      setLoading(false);
    } catch (error) {
      console.log('something went wrong!');
      setLoading(false);
    }
  };

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      {openAlertModal && <BaseModal {...baseModalProps} onClose={() => setOpenAlertModal(false)} withBackButton={false}>
        <div className={styles.alert_container}>
          <div className={styles.alert_title}>{t('change_slot_will_also_update_global_availability')}</div>
          <div className={styles.alert_footer}>
            <SaveButton disabled={loading} onClick={() => saveSlots()}>
              {loading ? <Loader type="TailSpin" color="#EB3B5A" height={20} width={20} /> : t('save')}
            </SaveButton>
          </div>
        </div>
      </BaseModal>}
      <EditableTextInput value={link.title!} onChange={titleChange} placeholder={t('add_title')} disabled={!editable} />

      {!editable && <Alert style={{fontWeight: 500, fontSize: '1rem'}} severity="warning">{t('monetization_is_disabled_for_your_account')}</Alert> }
      {!calendarConnected && <Alert style={{fontWeight: 500, fontSize: '1rem'}} severity="warning">{t('connect_calendar_to_create_link')}</Alert> }
      {!calendarConnected ? googleAuthComponent :
      <>
      <EditableImageInput
        value={link.customUploadedImage || ''}
        type={link.type}
        id={link.id}
        onChange={(customUploadedImage) =>
          setLink((prevState) => ({
            ...prevState,
            customUploadedImage,
          }))
        }
        editable={editable}
      />

  
          <div className={styles.availibility_title}>{t('availability')}</div>
          <AvailabilityModal handleChange={handleChange} />


      <TextInput
        value={link.note}
        onChange={(e) => setLink((prevState) => ({...prevState, note: e.target.value}))}
        disabled={!editable}
        label={t('give_your_buyer_a_small_teaser')}
        multiline
        minRows={5}
        error={validNote ? t('max_250_characters') : ''}
      />

      {googleAuthComponent}

      <div className={styles.duration_container}>
        {link?.price?.map((ele: any, index: number) => {
          return <div key={`duration-key-${ele.value}`} className={styles.single_container}>
          <div className={styles.duration_title}>
            {t('duration')}
            {index != 0 && <div onClick={() => handleDelete(index)} className={styles.clear}>{t('clear')}</div>}
          </div>
          <div className={styles.duration_box}>
            <div>
              <Select
                // value={selectedMins[index]}
                value={ele.value}
                onChange={(e) => handleDurationChange(e, index)}
                disabled={!editable}
                label={t('mins')}

              >
                {['15', '30', '45', '60'].map((key) => (
                  <Select.Item key={key} value={key} disabled={link?.price?.map((value: any) => value.value).includes(key) && ele.value != key}>
                    {key.toUpperCase()}
                  </Select.Item>
                ))}
              </Select>
            </div>
            <div className={styles.row_second}>
              <div className={styles.row}><PriceInput pickerData={{index}} link={link} onChange={(amount) => handleLinkBreakdown(index, {amount})} editable={editable} /></div>
              <div className={styles.row}>
                <Select
                  value={link.currency}
                  onChange={(e) => setLink({...link, currency: e.target.value as string})}
                  disabled={!editable}
                  label={t('currency')}

                >
                  {Object.keys(currencies).map((key) => (
                    <Select.Item key={key} value={key}>
                      {key.toUpperCase()}
                    </Select.Item>
                  ))}
                </Select>
              </div>
            </div>
            <div className={styles.row_divider}></div>
          </div>
        </div>;
        })}
      </div>

        {Array.isArray(link?.price) && link?.price?.length < 4 && (
          <PrimaryButton
            label={t('add_more')}
            color="black"
            onClick={handleAddDuration}
            style={{
              width: '120px',
              padding: '8px 26px',
              fontSize: '13px',
            }}
          />
        )}


      <LinkModal.EditControlsContainer>
        <ToggleButton
          checked={link.isVisible}
          onChange={(checked) =>
            setLink((prevState) => ({
              ...prevState,
              isVisible: checked,
            }))
          }
          label={t('visibility')}
        />

        {mode === 'edit' && <DeleteButton onClick={() => onDelete(link.id)}>{t('delete')}</DeleteButton>}
      </LinkModal.EditControlsContainer>

      <PriceBreakdown link={link} googleCalander={true} />
      </>}

      <PrimaryButton
        label={t('save')}
        onClick={() => handleScheduleUpdate()}
        disabled={!isValid || scheduledChange?.notValid || !calendarConnected} 
        style={{
          width: '50%',
          margin: 'auto',
          padding: '12px 16px',
        }}
      />

    </BaseModal>
  );
};

export default ShortCallLinkModal;
