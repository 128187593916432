import React, {useState, useEffect} from 'react';
import nonHookRequest from '../../features/API/nonHookRequest';
import {BaseModalProps} from '../LinkModal/components/BaseModal';
import BaseModal from '../LinkModal/components/BaseModal';
import styles from './AddCollectionModal.module.scss';
import Loader from 'react-loader-spinner';
import {PrimaryButton} from '../Buttons/PrimaryButton';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckIcon} from '../../assets/images/shop/check.svg';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {toast} from 'react-toastify';
import {raiseToast} from '../Toast/Toast';
import {CollectionEditingInfoProps} from '../../pages/TrackingLinks/TrackingLinksPage';
import {truncateUrl} from '../../Helper/generalHelper';

type Props = BaseModalProps & {
  collectionEditingInfo: CollectionEditingInfoProps;
  setCollectionEditingInfo: React.Dispatch<React.SetStateAction<CollectionEditingInfoProps>>;
  getTrackingLinks: () => Promise<void>;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>
};

const AddCollectionModal = ({
  collectionEditingInfo,
  setCollectionEditingInfo,
  getTrackingLinks, 
  setCurrPage, 
  onClose, 
  ...props
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<any[]>([]);
  const [productIds, setProductsIds] = useState<any[]>([]);
  const [productPrevIds, setProductsPrevIds] = useState<any[]>([]);
  const [name, setName] = useState("");

  const {t} = useTranslation();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    setName(collectionEditingInfo?.name || "");
    setProductsIds(collectionEditingInfo?.productIds || []);
    setProductsPrevIds(collectionEditingInfo?.productIds || []);
  }, [collectionEditingInfo]);

  const getProducts = async () => {
    setLoading(true);
    const response = await nonHookRequest({
      method: 'GET',
      url: `/brandshub/api/product?start=0&limit=100`,
      isShortUrl: true,
    });
    setProducts(response.products);
    setLoading(false);
  };

  const resetForm = () => {
    setName("");
    setProductsIds([]);
    setProductsPrevIds([]);
    setCollectionEditingInfo({});
  };

  const handleCreatingCollection = async () => {
    let response;
    if (collectionEditingInfo?.id) {
      response = await nonHookRequest({
        url: `/brandshub/api/collection/${collectionEditingInfo?.id}`,
        method: 'PUT',
        body: {
          name, 
          productIds,
        },
        isShortUrl: true,
        filterError: true,
      });
    } else {
      response = await nonHookRequest({
        url: `/brandshub/api/collection`,
        method: 'POST',
        body: {
          name, 
          productIds,
        },
        isShortUrl: true,
        filterError: true,
      });
    }

    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: t('tracking_link_added')});
      setCurrPage(1);
      getTrackingLinks();
      onClose({}, 'close');
      resetForm();
    };
  };

  const PaperProps= {
    style: {
      width: '80%',
      maxWidth: '900px',
      height: '700px',
    },
  };

  return (
    <BaseModal {...props} PaperProps={PaperProps} onClose={() => {
      onClose({}, 'close');
      resetForm();
    }}>
      <div className={styles.container}>
        <div>
          <h5>{t('collection_details')}</h5>
          <p>{t('select_products')}</p>

          <OutlinedInput
            className={styles.search}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={t('collection_title')}
          />

          {loading ? 
            <div className={styles.loader}>
              <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
            </div> :
            <div className={styles.mt20}>
              <div className={`${styles.dealsContainer} ${styles.customScrollbar}`}>
                {products?.length ? products.map((product, i) => {
                  const currentProductExist = productIds?.length && productIds.includes(product?.id);
                  return (
                    <div 
                      key={i} 
                      className={styles.dealContainer}
                    >
                      <div className={styles.imageContainer}>
                        <img
                          src={product?.logoUrl}
                          className={currentProductExist ? 
                            `${styles.dealImage} ${styles.selected}` : 
                            `${styles.dealImage}`}
                          alt=""
                          onClick={() => setProductsIds((prev) => currentProductExist ? 
                            prev.filter((item) => item !== product?.id) : 
                            [...prev, product?.id])
                          }
                        />
                        {productPrevIds.length && productPrevIds.includes(product?.id) ? <p 
                          className={styles.prevSelected}
                          onClick={() => setProductsIds((prev) => currentProductExist ? 
                            prev.filter((item) => item !== product?.id) : 
                            [...prev, product?.id])
                          }
                        >{t('in_collection')}</p> : null}
                        {currentProductExist ? <CheckIcon className={styles.checkMark} /> : null}
                      </div>
                      <div className={styles.textWrapper}>
                        <p className={styles.dealName}>{truncateUrl(product?.title, 18)}</p>
                      </div>
                    </div>
                  );
                }) : <h6>{t('no_deals')}</h6>}
              </div>
            </div>
          }
        </div>
        <div className={styles.footerContainer}>
          <p>{productIds.length} {t('products_selected')}</p>
          <PrimaryButton 
            label={collectionEditingInfo?.id ? t('edit_collection') : t('create_collection')}
            onClick={handleCreatingCollection}
            disabled={!name} 
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default AddCollectionModal;
