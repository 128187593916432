import React, {PropsWithChildren, useState} from "react";
import moment from 'moment';
import styles from './TrackingLinksTable.module.scss';
import nonHookRequest from "../../features/API/nonHookRequest";
import tableStyles from '../CommonScss/Table.module.scss';
import {Pagination} from "antd";
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import ConfirmationModal from "../LinkModal/modals/ConfirmationModal";
import {TrackingLinks} from "../../pages/TrackingLinks/TrackingLinksPage";
import {themeColor} from '../../constants/newThemes';
import {ReactComponent as TrashIcon} from '../../assets/images/al_trash_red2.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {Tooltip} from '@material-ui/core';
import {raiseToast} from "../Toast/Toast";
import {Grid} from "@material-ui/core";
import {ReactComponent as CopyIcon} from '../../assets/images/shop/copy.svg';
import {ReactComponent as DeleteIcon} from '../../assets/images/shop/delete.svg';
import {ReactComponent as DragIcon} from '../../assets/images/shop/drag.svg';
import {ReactComponent as EditIcon} from '../../assets/images/shop/edit.svg';
import {ReactComponent as VisibleTrueIcon} from '../../assets/images/shop/visible_true.svg';
import {ReactComponent as VisibleFalseIcon} from '../../assets/images/shop/visible_false.svg';
import {ReactComponent as AddIcon} from '../../assets/images/shop/add.svg';
import emptyProductImage from '../../assets/images/shop/empty_product.png';
import {truncateUrl} from "../../Helper/generalHelper";
import {PrimaryButton} from "../Buttons/PrimaryButton";
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';
import {EmptyState} from "./components/EmptyState";
import {ProductEditingInfoProps, CollectionEditingInfoProps} from '../../pages/TrackingLinks/TrackingLinksPage';
import copy from 'copy-to-clipboard';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {useSelector} from 'react-redux';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';

interface TrackingLinksTableProps {
  trackingLinks: any[];
  trackingLinksTotalCount: number;
  getTrackingLinks: () => Promise<void>;
  currPage: number;
  setCurrPage: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  isSearching: boolean;
  setOpenTrackingLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCollectionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setProductEditingInfo: React.Dispatch<React.SetStateAction<ProductEditingInfoProps>>;
  setCollectionEditingInfo: React.Dispatch<React.SetStateAction<CollectionEditingInfoProps>>;
};

const url = envConfig().copyLinkUrl;

const TrackingLinksTable = ({
  trackingLinks, 
  trackingLinksTotalCount, 
  getTrackingLinks, 
  currPage,
  setCurrPage,
  perPage,
  isSearching,
  setOpenTrackingLinkModal,
  setOpenCollectionModal,
  setProductEditingInfo,
  setCollectionEditingInfo,
}: TrackingLinksTableProps) => {
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openVisibilityConfirmationModal, setOpenVisibilityConfirmationModal] = useState(false);
  const [trackingLinkId, setTrackingLinkId] = useState<string | null>(null);
  const [isCurrentVisible, setIsCurrentVisible] = useState(false);
  const [type, setType] = useState<"product" | "collection" | null>();
  
  const {t} = useTranslation();
  const linksUser = useSelector(selectLinksUser);

  const handleCopyingProductLink = async (link: string) => {
    await navigator.clipboard.writeText(link);
    raiseToast({message: t('copied_to_clipboard')});
  };

  const handleCopyingCollectionLink = async (urlSlug: string) => {
    copy(`${url}/${linksUser?.nickname?.toLowerCase()}?collection=${urlSlug}`);
    raiseToast({message: t('copied_to_clipboard')});
  };

  const deleteTrackingLink = async () => {
    const response = await nonHookRequest({
      method: 'DELETE',
      url: type === 'product' ? 
        `/brandshub/api/product/${trackingLinkId}` :
        `/brandshub/api/collection/${trackingLinkId}`,
      isShortUrl: true,
      filterError: true,
    });
    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: type === 'product' ? t('product_deleted') : t('collection_deleted')});
      getTrackingLinks();
      setOpenDeleteConfirmationModal(false);
    };
  };

  const updateVisibility = async () => {
    const response = await nonHookRequest({
      method: 'PUT',
      url: type === 'product' ? 
        `/brandshub/api/product/${trackingLinkId}` :
        `/brandshub/api/collection/${trackingLinkId}`,
      isShortUrl: true,
      body: {visible: !isCurrentVisible},
      filterError: true,
    });
    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      getTrackingLinks();
      setOpenVisibilityConfirmationModal(false);
    };
  };

  const handleEditingProduct = (product: any) => {
    setProductEditingInfo({
      id: product?.id,
      productUrl: product?.productUrl,
      title: product?.title,
      logoUrl: product?.logoUrl,
      isVisible: product?.visible,
    });
    setOpenTrackingLinkModal(true);
  };

  const handleEditingCollection = (collection: any) => {
    setCollectionEditingInfo({
      id: collection?.id,
      name: collection?.name,
      productIds: collection?.products?.length ? collection?.products.map((elem: any) => elem.product.id) : [],
    });
    setOpenCollectionModal(true);
  };

  const onDragEnd = async (result: DropResult) => {
    const response = await nonHookRequest({
      method: 'PUT',
      url: `/brandshub/api/products-collections/displayOrder`,
      isShortUrl: true,
      body: {entityId: result?.draggableId, newOrder: result?.destination?.index},
      filterError: true,
    });
    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: ""});
      getTrackingLinks();
    };
  };

  return !trackingLinks?.length ? (
    isSearching ? 
    <div className={styles.emptyContainer}>
      <h3 className={styles.emptyHeading}>{t('no_search_result')}</h3>
      <p className={styles.emptysubHeading}>{t('click_to_add')}</p>
    </div> :
    <EmptyState setOpenTrackingLinkModal={setOpenTrackingLinkModal} />
  ): 
  (
    <div className={styles.container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className={styles.productsContainer}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {trackingLinks.map((trackingLink, i) => {
                const isCollection = !!trackingLink?.collection;
                const heading = isCollection ? trackingLink?.collection?.name : trackingLink?.product?.userDeal?.deal?.name;
                const subHeading = isCollection ? `${trackingLink?.collection?.products?.length} item${trackingLink?.products?.length !== 1 ? 's' : ''}` : trackingLink?.product?.title;
                return (
                  <Draggable key={i} draggableId={trackingLink.id} index={trackingLink.displayOrder}>
                    {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={styles.productContainer}
                    >
                      <div style={{display: 'flex'}}>
                        <div {...provided.dragHandleProps}>
                          <DragIcon style={{cursor: 'grab'}} />
                        </div>
                        <span style={{margin: '0 auto'}}></span>
                        <CopyIcon 
                          onClick={() => isCollection ? 
                            handleCopyingCollectionLink(trackingLink?.collection?.urlSlug) : 
                            handleCopyingProductLink(trackingLink?.product?.trackingLink)
                          } 
                          style={{cursor: 'pointer'}} 
                        />
                      </div>
                      <div className={styles.productInfoContainer}>
                        {isCollection ? 
                          <div style={{display: 'flex', flexWrap: 'wrap'}}>
                            {trackingLink?.collection?.products?.length ?
                              <>
                                {trackingLink?.collection?.products?.slice(0, 4)?.map((product: any, i: number) => {
                                  return (
                                    <img 
                                      src={product?.product?.logoUrl} 
                                      className={styles.collectionLogo} 
                                      alt=""
                                      key={i}
                                    />
                                  );
                                })}
                                {trackingLink?.collection?.products?.length < 4 && Array(4 - trackingLink?.collection?.products?.length).fill(0).map((element, i: number) => (
                                  <img 
                                    key={i} 
                                    src={emptyProductImage} 
                                    className={styles.collectionLogo} 
                                    alt=""
                                  />
                                ))}
                              </> :
                              <div 
                                className={`${styles.trackingLogo} ${styles.AddIcon}`}
                                onClick={() => handleEditingCollection(trackingLink?.collection)}
                              >
                                <AddIcon />
                                <p>{t('add_product_to_collection')}</p>
                              </div>
                            }
                          </div> :
                          <img src={trackingLink?.product?.logoUrl} className={styles.trackingLogo} alt=""/>
                        }
                        <span className={styles.cardHeading}>{truncateUrl(heading, 20)}</span>
                        <span className={styles.cardSubheading}>{truncateUrl(subHeading, 20)}</span>
                      </div>
                      <div style={{display: 'flex'}}>
                        {(isCollection && trackingLink?.collection?.visible) || 
                        (!isCollection && trackingLink?.product?.visible) ? (
                          <VisibleTrueIcon 
                            onClick={() => {
                              setType(isCollection ? "collection" : "product");
                              setTrackingLinkId(isCollection ? trackingLink?.collection?.id : trackingLink?.product?.id);
                              setIsCurrentVisible(isCollection ? trackingLink?.collection?.visible : trackingLink?.product?.visible);
                              setOpenVisibilityConfirmationModal(true);
                            }}
                            style={{cursor: 'pointer'}}
                          />
                        ) : (
                          <VisibleFalseIcon 
                            onClick={() => {
                              setType(isCollection ? "collection" : "product");
                              setTrackingLinkId(isCollection ? trackingLink?.collection?.id : trackingLink?.product?.id);
                              setIsCurrentVisible(isCollection ? trackingLink?.collection?.visible : trackingLink?.product?.visible);
                              setOpenVisibilityConfirmationModal(true);
                            }}
                            style={{cursor: 'pointer'}}
                          />
                        )}
                        <span style={{margin: '0 auto'}}></span>
                        <EditIcon 
                          onClick={() => {
                            isCollection ?
                              handleEditingCollection(trackingLink?.collection) : 
                              handleEditingProduct(trackingLink?.product);
                          }} 
                          style={{cursor: 'pointer'}} 
                        />
                        <span style={{margin: '0 5px'}}></span>
                        <DeleteIcon 
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setType(isCollection ? "collection" : "product");
                            setTrackingLinkId(isCollection ? trackingLink?.collection?.id : trackingLink?.product?.id);
                            setOpenDeleteConfirmationModal(true);
                          }}
                          style={{cursor: 'pointer'}}
                        />
                      </div>
                    </div>
                    )}
                  </Draggable>
                );
              })};
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <ConfirmationModal
        title={t('sure_delete_product')}
        primaryButtonText={t('yes_delete_link')}
        handlePrimaryButtonClick={deleteTrackingLink}
        secondaryButtonText={t('no_keep_link')}
        open={openDeleteConfirmationModal}
        onClose={() => {
          setOpenDeleteConfirmationModal(false);
        }}
      />

      <ConfirmationModal
        title={isCurrentVisible ? t('hide_product_confirmation') : t('publish_product_confirmation')}
        primaryButtonText={isCurrentVisible ? t('yes_hide') : t('yes_publish')}
        handlePrimaryButtonClick={updateVisibility}
        secondaryButtonText={isCurrentVisible ? t('no_publish') : t('no_hide')}
        open={openVisibilityConfirmationModal}
        onClose={() => {
          setOpenVisibilityConfirmationModal(false);
        }}
      />
    </div>
  );
};

export default TrackingLinksTable;
