/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from '../CommonScss/Table.module.scss';
import stylesBox from './RequestTable.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getConfig } from '../../features/stores/configSlicer';
import { getDateBasedOnLocalTimezone, haveSegmentPermission } from '../../features/util';
import { getCurrencyLabel } from '../../constants/currencies';
import { monetizeImageMap } from '../../constants/socialMedia';
import EventIcon from '@material-ui/icons/Event';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { PrimaryButton } from '../Buttons/PrimaryButton';

interface BuyerMeta {
  name: string;
  email: string;
  notes: string;
  phone: string;
  details?: {
    startDate: string;
    endDate: string;
    timezone: string;
  };
}

interface Request {
  id: string;
  linkType: string;
  linkTitle: string;
  buyerMeta: BuyerMeta;
  currency: string;
  amount: number;
  createdAt: string;
  status: string;
  type: string;
}

interface RequestTableProps {
  requestList: Request[];
  setOpenTaskCompleteModal: (open: boolean) => void;
  setTaskIDObj: (task: { taskID: string; type: string }) => void;
}

const RequestTable: React.FC<RequestTableProps> = ({
  requestList,
  setOpenTaskCompleteModal,
  setTaskIDObj,
}) => {
  const { t } = useTranslation();
  const [openAccordionId, setOpenAccordionId] = useState<string>('');
  const config = useSelector(getConfig).data || [];
  const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];

  const handleToggleAccordion = (id: string) => {
    setOpenAccordionId((prevId) => (prevId === id ? '' : id));
  };

  const handleComplete = (id: string, type: string) => {
    if (setTaskIDObj && setOpenTaskCompleteModal) {
      setTaskIDObj({ taskID: id, type });
      setOpenTaskCompleteModal(true);
    }
  };

  const getCurrencyValue = (amount: number, currency: string) => {
    const conversionFactor = currencyMap.find((e: any) => e.currency === (currency || 'usd'))?.conversionFactor || 100;
    return (amount / conversionFactor).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getStatusButtons = (request: Request) => {
    const { t } = useTranslation();
    const { id, status, type } = request;

    if (['Completed', 'Rejected', 'Reverted', 'Returned', 'Failed'].includes(status)) return null;

    const handleButtonClick = (actionType: string) => {
      handleComplete(id, actionType);
    };

    if (status === 'Accepted' || (status === 'In Review' && type === 'tipJar')) {
      return (
        <PrimaryButton
          label={t('complete')}
          color="red"
          onClick={() => handleButtonClick('Completed')}
        />
      );
    }

    if (status === 'In Review' && type !== 'tipJar') {
      return (
        <>
          <PrimaryButton
            label={t('accept')}
            color="red"
            onClick={() => handleButtonClick('Accepted')}
            style={{ marginRight: '8px' }} // Optional margin for spacing
          />
          <PrimaryButton
            label={t('reject')}
            color="black"
            onClick={() => handleButtonClick('Rejected')}
          />
        </>
      );
    }

    return null;
  };

  const renderNotes = (notes: string, id: string, size = 60) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (!notes || notes.length <= size) return notes || '--';
    return (
      <div className={`${stylesBox.notes} ${styles.notes}`}>
        {isExpanded ? notes : `${notes.substring(0, size)}...`}
        <button className={styles.show_less_btn} onClick={() => setIsExpanded(!isExpanded)}>
          {t(isExpanded ? 'show_less' : 'show_more')}
        </button>
      </div>
    );
  };

  return (
    <div className={styles.tablemain}>
      <div className={stylesBox.container}>
        {requestList.map((request) => {
          const {
            id,
            linkType,
            linkTitle,
            buyerMeta,
            currency,
            amount,
            createdAt,
            status,
          } = request;
          const currencyLabel = getCurrencyLabel(currency);
          const startDate = buyerMeta?.details?.startDate;
          const endDate = buyerMeta?.details?.endDate;
          const timeZone = buyerMeta?.details?.timezone;
          const isExpanded = id === openAccordionId;

          return (
            <div
              className={`${stylesBox.single_box} ${isExpanded ? styles.highlight : ''}`}
              key={id}
            >
              <div className={stylesBox.single_inner_box}>
                <div className={stylesBox.header_row} onClick={() => handleToggleAccordion(id)}>
                  <div className={stylesBox.column_img}>
                    <img src={monetizeImageMap[linkType]} alt="Link Type" />
                  </div>
                  <div className={stylesBox.column_right}>
                    <div className={stylesBox.title}>{linkTitle}</div>
                    <div className={stylesBox.amount}>
                      <div className={stylesBox.heading_two}>{buyerMeta?.name || ''}</div>
                    </div>
                    <div className={stylesBox.email}>
                     {buyerMeta?.email || ''}
                    </div>
                    <div className={stylesBox.date}>
                     {buyerMeta?.phone || ''}
                    </div>
                    {startDate && endDate && (
                      <div className={stylesBox.date}>
                        {`${getDateBasedOnLocalTimezone(startDate, timeZone, 'DD MMM YYYY')}, 
                          ${getDateBasedOnLocalTimezone(startDate, timeZone, 'hh:mm A')} - 
                          ${getDateBasedOnLocalTimezone(endDate, timeZone, 'hh:mm A')}`}
                      </div>
                    )}
                  </div>
                  <div className={stylesBox.amountRight}>
                    <div>{currencyLabel}{getCurrencyValue(amount, currency)}</div>
                    <div className={stylesBox.date}>
                      {moment(createdAt).format('MMM DD, YYYY')}
                    </div>
                  </div>
                </div>
                <div className={`${stylesBox.box_body} ${isExpanded ? stylesBox.expanded : ''}`} style={{ minHeight: '4rem' }}>
                  {renderNotes(buyerMeta?.notes, id)}
                </div>

                <div className={stylesBox.action_btn}>{getStatusButtons(request)}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RequestTable;
