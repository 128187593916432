import React from 'react';
import styles from './LinkItem.module.scss';

import {ReactComponent as DragIcon} from '../../assets/images/menu.svg';
import {ReactComponent as ArrowIcon} from '../../assets/images/keyboard_arrow_right.svg';
import {ReactComponent as TrashIcon} from '../../assets/images/al_trash_red2.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {DraggableProvided} from 'react-beautiful-dnd';
import {Tooltip} from '@material-ui/core';
import OverflowTooltip from '../OverflowTooltip/OverflowTooltip';
import {useTranslation} from 'react-i18next';

type Props = {
  visibility: boolean;
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
  onClick: () => void;
  draggableProvided: DraggableProvided;
  handleDelete: () => void;
  toggleVisibility: () => void;
};

const LinkItem = ({
  visibility,
  title,
  subtitle,
  icon,
  draggableProvided,
  onClick,
  toggleVisibility,
  handleDelete,
}: Props) => {
  const {t} = useTranslation();
  return (
    <div className={styles.cardContainer} ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
      <div className={styles.dargHandle} {...draggableProvided.dragHandleProps}>
        <DragIcon />
      </div>
      <div className={styles.container} onClick={onClick}>
        <div className={styles.imageContainer}>{icon}</div>
        <div className={styles.mainContent}>
          <OverflowTooltip className={styles.title} title={title} arrow placement='top'>
            <span>{title}</span>
          </OverflowTooltip>
          {!!subtitle && <OverflowTooltip className={styles.subtitle} title={subtitle} arrow placement='top'>
            <span>{subtitle}</span>
          </OverflowTooltip>}
        </div>
        <div className={styles.leftSection}>
          <div className={styles.actions}>
            {/* <Tooltip arrow placement='top' title={visibility ? 'Hide' : 'Show'} onClick={(e: any) => {
              e.stopPropagation();
              toggleVisibility();
            }}>
              {visibility ? (
                <VisibilityIcon style={{width: 24, height: 24}} htmlColor='#7066ff' />
              ) : (
                <VisibilityOffIcon style={{width: 24, height: 24}} htmlColor='#7066ff' />
              )}
            </Tooltip> */}
            {/* <div
              onClick={(e: any) => {
                e.stopPropagation();
                handleDelete();
              }}
            >
              <TrashIcon width={20} height={20} />
            </div> */}
          </div>
          <ArrowIcon className={styles.editBtn} />
        </div>
      </div>
    </div>
  );
};

export default LinkItem;
