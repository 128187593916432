import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styles from './UpdatePassword.module.scss';
import Loader from 'react-loader-spinner';
import {Input} from 'antd';
import useRequest from '../../../features/API/request';
import {toast} from 'react-toastify';
import {getSession} from '../../../features/Login/login';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import TextFieldInput from '../../../components/TextFieldInput/TextFieldInput';
  
const UpdatePassword = () => {
  const {t} = useTranslation();
  const [currentValue, setCurrentValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmValue, setConfirmValue] = useState('');
  const [loading, setLoading] = useState(false);
  const updateProfileRequest = useRequest({method: 'POST', url: '/linksUser/change-password'});

  const handleUpdate = async () => {
    const reg = /^.{6,99}$/;
    if (!currentValue) {
      toast.error(t('enter_current_password'));
    } else if (!passwordValue && !confirmValue) {
        toast.error(t('enter_both_password'));
    } else if (!passwordValue || !confirmValue || passwordValue !== confirmValue) {
        toast.error(t('passwords_must_match'));
    } else if (!reg.test(passwordValue) || !reg.test(confirmValue)) {
        toast.error(t('passwords_must_be_at_least_six_characters_long'));
    } else {
        setLoading(true);
        const tokens = await getSession(false, true);
        const payload = {newPassword: passwordValue, currentPassword: currentValue};
        const result = await updateProfileRequest.doRequest(payload, {headers: {'c-access-key': tokens?.accessToken?.jwtToken || undefined}});
        setLoading(false);
        if (result) {
            toast.success(<span style={{color: 'white'}}>{t('password_updated')}</span>);
            setCurrentValue('');
            setPasswordValue('');
            setConfirmValue('');
        }
    }
  };

  useEffect(() => {
    if (updateProfileRequest.hasError) {
      toast.error(<span style={{color: 'white'}}>{updateProfileRequest?.error?.message || t('something_went_wrong')}</span>);
    }
  }, [updateProfileRequest.hasError]);

    return (
      <div className={styles.contract_container}>
       <div className={styles.contract_body}>
        <div className={loading ? styles.loader : styles.password_body}>
        {loading ? (
            <Loader type="TailSpin" color="#FF002B" height={80} width={80} />
          ) : (
            <>
              <TextFieldInput
                label={t('enter_current_password')}
                type="password"
                placeholder={t('enter_current_password')}
                value={currentValue}
                onChange={(e: any) => setCurrentValue(e.target.value)}
              />
              <TextFieldInput
                label={t('enter_password')}
                type="password"
                placeholder={t('enter_password')}
                value={passwordValue}
                onChange={(e: any) => setPasswordValue(e.target.value)}
              />
              <TextFieldInput
                label={t('confirm_password')}
                type="password"
                placeholder={t('confirm_password')}
                value={confirmValue}
                onChange={(e: any) => setConfirmValue(e.target.value)}
              />
              <div className={styles.buttons_wrapper}>
                <PrimaryButton
                  label={t('save')}
                  onClick={handleUpdate}
                  color="red"
                  disabled={updateProfileRequest.isFetching}
                  style={{ padding: '10px 120px' }}
                />
              </div>
            </>
          )}
        </div>
        </div>
      </div>
    );
  };
  
  export default UpdatePassword;
